import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import { serverLink} from "../../components/url";
import {showAlert} from "../../components/common/sweetalert/sweetalert";
import axios from "axios";
import { toast } from 'react-toastify';
import banner from "../../images/login_pics-min.png";
import {setAttendanceID, setHomeReport, setStudentData, setTimestamp} from "../../action/actions";
import StudentRow from "./student-image-card";
import { useReactToPrint } from "react-to-print";

const IdentityLogin = (props) => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [IsLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [student, setStudent] = useState([]);
    const [cohortData, setCohortData] = useState([]);
    const componentRef = useRef();
    const [printPage, setprintPage] = useState(false);
    const [formData, setFormData] = useState({
        Username: "",
    });

    const onPrintPage = () => {
        setprintPage(true);
        setTimeout(() => {
            handlePrint();
            setprintPage(false);
        }, 1000);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.Username.toString().trim() === "") {
            toast.error("Please enter your email address");
            return false;
        }

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}foundation/dashboard/programme/id-card`, formData)
            .then( async (result) => {
                const response = result.data.message;
                if (response === 'success') {
                    const cohort = result.data.cohort;
                    const student_data = result.data.student;
                    if (student_data.length > 0){
                        setCohortData(cohort)
                        setStudent(student_data)
                        // toast.success("You are successfully logged in");
                        setFormData({
                            ...formData,
                            Username: "",
                        })
                        setShow(true)
                        setIsLoading(false)
                    }else{
                        toast.error("Student record not found, please try again!");
                    }

                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            }).catch(error => {
            showAlert(
                "NETWORK ERROR",
                "Please check your network connection and try again!",
                "error"
            );
            setIsLoading(false)
        })
    }


    return (
        <>
            <section className="teacher__area mb-5">
                <div className="container">
                    {
                        !show ?
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                <div className="row">
                                    <div className="col-md-6 hide-mobile-view" style={{background: `url(${banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '700px', display: 'none'}}></div>
                                    <div className="col-md-6"  style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px'}}>
                                        <div className=" row d-flex justify-content-center align-items-center h-100">
                                            <div className="col-md-12 mt-5">
                                                <div className=" mb-2">
                                                    <form className=" bg-white p-3">
                                                        <div className="text-left mb-30">
                                                            <div className="section-title">
                                                                <h1 className="center-text" style={{textAlign: 'center'}}>Print Your ID Card</h1>
                                                                <div className="text-left" style={{textAlign: 'left '}}>Enter you email address to print your ID Card</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" id="Username" name="Username" value={formData.Username} onChange={handleChange} placeholder="Email Address"/><span
                                                            className="focus-border"></span></div>
                                                        <button type="button" onClick={handleSubmit} className="btn btn-success mt-3 w-100">SUBMIT</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                </div>
                            :
                            <div ref={componentRef} className="mt-4"> <StudentRow students={student} show={show} setShow={setShow} />
                                <button
                                    id="hide-on-print"
                                    type="button"
                                    className="btn btn-sm btn-primary "
                                    style={{ marginBottom: "15px" }}
                                    onClick={onPrintPage}
                                >
                                    Print
                                </button>
                            </div>
                    }
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStudentData: (p) => {
            dispatch(setStudentData(p))
        },
        setOnAttendanceID: (p) => {
            dispatch(setAttendanceID(p))
        },
        setOnTimestamp: (p) => {
            dispatch(setTimestamp(p))
        },
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(IdentityLogin);