import React from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import certificate from "../../images/about.png";

const About = () => {

    return (
        <>
            <Breadcrumb page_name={"About IDEAS Programme"}/>

            <section className="faq__area pt-150 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <img className=" mb-3"
                                 src={certificate}
                                 alt="Image"
                                 style={{height: '360px', width: '360px'}}
                            />
                        </div>


                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="faq__wrapper ">
                                <div className="section__title-wrapper mb-5">
                                    <h2 className="section__title section__title-44" style={{color: "#2E8B57"}}><b>About
                                        IDEAS Programme</b></h2>
                                </div>
                                <p style={{textAlign: 'justify'}}>
                                    This comprehensive Programme is strategically designed to empower Nigerian youths
                                    hailing from diverse backgrounds, arming them with the knowledge and skills
                                    necessary to not only excel in their professional lives but also to actively
                                    contribute to the socio-economic development of their respective communities. <br/>
                                    <br/>
                                    The Federal Government of Nigeria through Federal Ministry of Education has obtained
                                    a Credit to finance a new World Bank-supported project called: Innovation
                                    Development and Effectiveness in the Acquisition of Skills (IDEAS) Project aim at
                                    improving the country’s TVET System. The objective of IDEAS project is to improve
                                    skills acquisition in Nigeria using a comprehensive approach and addressing key
                                    aspects of the skills development delivery system. It leverages industry involvement
                                    for better labour market fit and crowding in of private resources in the formal
                                    training system.
                                    <br/><br/>
                                    What distinguishes this Programme is its steadfast commitment to inclusivity. It
                                    extends a cordial invitation to individuals of all genders, backgrounds, and
                                    physical abilities. We firmly believe that genuine progress and sustainable
                                    development can only be realized when all voices are acknowledged, and all talents
                                    are cultivated. As such, our program welcomes anyone with the ambition to learn,
                                    grow, and make a positive impact.
                                    <br/><br/>
                                    This comprehensive programme is designed to provide participants with a wholistic
                                    preparation for entry-level opportunities. The curriculum encompasses hands-on
                                    experiences during training, coupled with internships that offer a platform to
                                    refine and comprehend work in accordance with industry standards.
                                    <br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default About;
