import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isWhiteBackground, serverLink } from "../../components/url";
import { showAlert } from "../../components/common/sweetalert/sweetalert";
import axios from "axios";
import { toast } from "react-toastify";
import "./style.css";
import { CirclesWithBar } from "react-loader-spinner";

const UploadPassport = (props) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const lastCharacter = Number(window.location.pathname.match(/(\d+)$/)[0]);
  const [formData, setFormData] = useState({
    APPID: "",
    NIN: "",
    ImagePath: "",
    ImageName: "",
  });

  const getData = async () => {
    const student = lastCharacter;
    await axios
      .get(`${serverLink}foundation/verify-student/${student}`)
      .then((res) => {
        if (res.data.message === "success") {
          if (res.data.data.length > 0) {
            let student = res.data.data[0];
            setFormData({
              ...formData,
              APPID: student,
              NIN: student.NIN,
              EMAIL: student.EmailAddress,
            });
          } else {
            navigate("/");
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
    } else {
      toast.error("Only .png, .jpg and .jpeg format allowed!");
      return false;
    }
    if (file.size > 200000) {
      toast.error("max file size is 200KB");
      return false;
    }

    reader.onload = () => {
      setFormData({
        ...formData,
        ImagePath: file,
        ImageName: file.name,
        ImageDisplay: reader.result,
      });
      setSelectedPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    setIsFormLoading(true);
    e.preventDefault();
    if (formData.APPID.toString().trim() === "") {
      toast.error("Student ID can not be empty");
      return false;
    }

    if (formData.ImagePath.toString().trim() === "") {
      toast.error("Please select a photo");
      return false;
    }

    toast.info("Please wait...");
    await axios
      .get(`${serverLink}foundation/verify-passport-upload/${lastCharacter}`)
      .then((res) => {
        if (res.data.length > 0) {
          toast.error("Your passport has already been uploaded");
        } else {
          const formData2 = new FormData();
          formData2.append("photo", formData.ImagePath);
          formData2.append("APPID", lastCharacter);
          formData2.append("NIN", formData.NIN);

          axios
            .patch(`${serverLink}foundation/upload-student-passport`, formData2)
            .then(async (res) => {
              const response = res.data.message;
              if (response === "success") {
                toast.success("Passport Uploaded Successfully");
                setIsFormLoading(false);
                setFormData({
                  ...formData,
                  APPID: "",
                  ImagePath: "",
                });
                setTimeout(() => {
                  navigate("/");
                }, 3000);
                window.location.href = `https://ideas.bazeuniversity.edu.ng/admission-letter/${lastCharacter}`;
              } else {
                showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
                );
              }
            })
            .catch((err) => {
              setIsFormLoading(false);
              showAlert(
                "ERROR",
                "Something went wrong uploading the image. Please try again!",
                "error"
              );
            });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  // useEffect(() => {
  //     try {
  //         if (slug === "") {
  //             navigate("/");
  //         }
  //         setFormData({...formData, APPID: getAppID()})
  //     } catch (error) {
  //         console.error('Error decoding Base64 string:', error.message);
  //         navigate("/");
  //     }
  //
  // }, [location]);

  return IsLoading ? (
    <CirclesWithBar
      height="100"
      width="100"
      color="#999999"
      wrapperStyle={{ paddingTop: "300px", paddingLeft: "50%" }}
      wrapperClass=""
      visible={true}
      outerCircleColor=""
      innerCircleColor=""
      barColor=""
      ariaLabel="circles-with-bar-loading"
    />
  ) : (
    <>
      <section className="teacher__area pt-120 pb-110">
        <div className="container">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="row">
              <div
                className="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div className="row">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-8 text-center">
                        <div className="section-title">
                          <h2>UPLOAD PASSPORT</h2>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-7 mb-2">
                        <form className="login bg-white shadow p-5">
                          <div className="form-group">
                            <div className="passport-upload-box">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handlePhotoChange}
                                id="upload-photo"
                                style={{ display: "none" }}
                              />
                              <label htmlFor="upload-photo">
                                <div className="table-responsive-md">
                                  <div className="upload-box">
                                    <span>Choose Photo</span>
                                    {selectedPhoto && (
                                      <img src={selectedPhoto} alt="Selected" />
                                    )}
                                  </div>
                                </div>
                              </label>
                            </div>
                            <span className="focus-border"></span>
                          </div>
                          <div className="alert alert-info mt-4" role="alert">
                            <h4 className="alert-heading">
                              Passport Photo Guidelines!
                            </h4>
                            <hr />
                            <p>
                              <ol>
                                <li>
                                  <b>Image Size Limitation:</b> Please ensure
                                  that the image size does not exceed 200KB.
                                </li>
                                <li>
                                  <b>Background Requirement: </b> The image
                                  should feature a white background to comply
                                  with passport standards.
                                </li>
                                <li>
                                  <b>Clarity Expectation:</b> Ensure the image
                                  is clear and not blurry for optimal
                                  processing.
                                </li>
                              </ol>
                            </p>
                            <hr />
                            <p className="mb-0">
                              Failure to adhere to these guidelines may result
                              in rejection of your submission. Please review
                              your passport photo carefully before submission.
                            </p>
                          </div>
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-success mt-3 w-100"
                          >
                            SUBMIT
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    homePageData: state.HomeReport,
  };
};

export default connect(mapStateToProps, null)(UploadPassport);
