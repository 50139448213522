import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {setHomePageData} from "../../../action/actions";
import {Link} from "react-router-dom";
import logo from "../../../images/logo_two.png";


const Overlay = (props) => {

    const dismissClick = () => {
        document.getElementById("toggle-nav").click();
    }

    return (
        <>
            <div className="offcanvas__area">
                <div className="modal fade" id="offcanvasmodal" tabIndex="-1" aria-labelledby="offcanvasmodal"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="offcanvas__wrapper">
                                <div className="offcanvas__content">
                                    <div
                                        className="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
                                        <div className="offcanvas__logo logo">
                                            <a href="index.html">
                                                <img src={logo} alt="logo" />
                                            </a>
                                        </div>
                                        <div className="offcanvas__close">
                                            <button className="offcanvas__close-btn" id="toggle-nav" data-bs-toggle="modal"
                                                    data-bs-target="#offcanvasmodal">
                                                <i className="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="offcanvas__search mb-25">
                                        <form action="#">
                                            <input type="text" placeholder="What are you searching for?" />
                                                <button type="submit"><i className="far fa-search"></i></button>
                                        </form>
                                    </div>

                                    <div className="mobile-menu fix mean-container">
                                        <div className="mean-bar"><a href="#nav" className="meanmenu-reveal" style={{ right: "0px", left: "auto", display: "inline" }}><span><span><span></span></span></span></a>

                                            <nav className="mean-nav">
                                                <ul>
                                                    <li>
                                                        <Link onClick={()=>{ dismissClick() }} to={"/home"}>Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={()=>{ dismissClick() }} to={"/our-programs"}>Programmes</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={()=>{ dismissClick() }}  to={"/programme-guide"}>Programme Guideline</Link>
                                                    </li>
                                                    <li>
                                                        <Link  onClick={()=>{ dismissClick() }}  to={"/implementation-team"}>Implementation Team</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={()=>{ dismissClick() }}  to={"/news"}>News</Link>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="#">About</a>
                                                        <ul className="submenu">
                                                            <li><a onClick={()=>{ dismissClick() }}  target='_blank' href="https://bazeuniversity.edu.ng/"> Baze University</a></li>
                                                            <li><a onClick={()=>{ dismissClick() }}  target='_blank' href="https://ideasproject.gov.ng/about-ideas-project/">IDEAS Project</a></li>
                                                            <li><a onClick={()=>{ dismissClick() }}  target='_blank' href="https://domineum.io/">Domineum</a></li>
                                                            <li><Link onClick={()=>{ dismissClick() }}  to="/ideas-project">IDEAS Overview </Link></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link onClick={()=>{ dismissClick() }}  to={"/contact"}>Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    {/*<div className="offcanvas__map d-none d-lg-block mb-15">*/}
                                    {/*    <iframe*/}
                                    {/*        src="https://www.google.com/maps/dir/9.0063752,7.4041769/baze+university+google+map/@9.0069148,7.4027285,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x104e7372e355f317:0x7f90ec48624fd5b4!2m2!1d7.4059828!2d9.0073052"></iframe>*/}
                                    {/*</div>*/}

                                    {/*<div className="offcanvas__social">*/}
                                    {/*    <ul>*/}
                                    {/*        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-youtube"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-linkedin"></i></a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-overlay"></div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomePageDataReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
