import React from "react";
import {serverLink} from "../url";
import logo from './grade.png';
import idea from './logos (1).png';
import "./style.css";
function StudentCard({ students }) {
    const containerStyle = {
        width: "50%", // Each card occupies 25% of the row width
        padding: "10px",
        boxSizing: "border-box",
        position: "relative",
        display: "inline-block",
        verticalAlign: "top",
        border: '1px solid #cccccc'
    };

    const cardStyle = {
        position: "relative",
        display: 'block',
        justifyItems: 'center',
        // paddingBottom: "100%", // Maintain aspect ratio (1:1)
        // overflow: 'hidden',
        borderRadius: "5px",
    };

    const imageStyle = {
        width: '70%',
        padding: '20px',
        objectFit: "cover", // Maintain aspect ratio
    };

    const detailsContainerStyle = {
        backgroundColor: "#fff",
        padding: "5px",
        borderTop: "1px solid #ccc",
        textAlign: "left",
        fontSize: "11px",
    };

    const logoStyle = {
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translate(-30%, 50%)", // Center horizontally and vertically
        zIndex: 1, // Ensure logo is above the image
        width: "50px", // Adjust as needed
        height: "40px", // Adjust as needed
        borderRadius: "100%",
    };


    const getDisabilityStatus = (disability) => {
        if (disability === 1) {
            return "Disabled";
        }
        if (disability === 0) {
            return "Normal";
        }
    };

    return (
        // {
        //     "StudentID": "IDEAS/24/4016",
        //     "EmailAddress": "yasiraumar43@gmail.com",
        //     "PhoneNumber": "+2347069956895",
        //     "Cohort": "24",
        //     "State": "Niger",
        //     "LGA": "Chanchaga",
        //     "FirstName": "Yasira ",
        //     "MiddleName": "",
        //     "Surname": "Umar",
        //     "CourseName": "Professional Diploma in Applied Artificial Intelligence"
        // }

        <div style={containerStyle}>
            <div className="card watermarked-div" style={cardStyle}>
                <p></p>
                <div className=" align-items-center justify-content-start mb-2" style={{marginTop: '-25px'}}><img src={idea} width={250} height={72} style={{marginRight: '10px'}} /><br/><h4 className="mt-2">Baze-Domineum IDEAS Programme</h4></div>
                <img
                    src={`https://ideas.api.bazeuniversity.edu.ng:5001/public/uploads/ideas_project/passport/${students.Passport}`}
                    alt="Student Passport"
                    style={imageStyle}
                />
                <div className="mt-3"><h3>Student</h3></div>
                <img src={logo} style={logoStyle} />

            </div>
            <div style={detailsContainerStyle}>
                <div
                    style={{ fontSize: "20px", marginBottom: "5px", color: "#437340" }}
                >
                    <strong>
                        {students.FirstName} {students.MiddleName} {students.Surname}
                    </strong>{" "}
                </div>
                <span>
          <strong>Student ID:</strong> {students.StudentID}
        </span>
                <br />
                <span>
          <strong>Course:</strong> {students.CourseName}
        </span>
                <br />
                <span>
          <strong>Email:</strong> {students.EmailAddress}
        </span>
                <br />
            </div>
        </div>
    );
}

export default function StudentRow({ students, show, setShow }) {
    const rowStyle = {
        margin: "0 -10px",
        textAlign: "center",
    };

    return (
        <div style={rowStyle}>
            <div id="hide-on-print" className="d-flex justify-content-between ">
                <h4 id="hide-on-print" style={{ textAlign: "left", marginLeft: "15px", color: "black" }}>
                    Student ID Card
                </h4>
                <button id="hide-on-print" className="btn btn-primary btn-sm" onClick={()=>{setShow(!show)}}><span className="fa fa-home"/> Home</button>
            </div>

            <hr  id="hide-on-print" />
            {students.slice(0,1).map((students, index) => (
                <StudentCard key={index} students={students} />
            ))}
        </div>
    );
}
