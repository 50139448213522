import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../home/home";
import Header from "../../common/header/header";
import HeaderSticky from "../../common/header-sticky/header-sticky";
import Footer from "../../common/footer/footer";
import Overlay from "../../common/overlay/overlay";
import LoaderProgressBar from "../../common/loader-progress-bar/loader-progress-bar";
import Slider from "../../common/slider/slider";
import OurProgram from "../../course/our-program/our-program";
import CourseDetail from "../../course/detail/course-detail";
import Contact from "../../contact/contact";
import StaffProfile from "../../staff-profile/staff-profile";
import AdmissionAndFees from "../../admission-fees/admission-fees";
import AdmissionLetter from "../../admission-letter/admission-letter";
import NewsDetails from "../../news/news-details";
import News from "../../news/news";
import About from "../../about/about";
import ImplementationTeam from "../../implementation-team/implementation-team";
import TeamProfile from "../../implementation-team/team-profile";
import Validation from "../../validation/validation";
import StudentLogin from "../../classes/login";
import Player from "../../classes/player";
import UploadPassport from "../../classes/upload-passport";
import Gallery from "../../gallery/gallery";
import ChangeProgramme from "../../update/change_programme";
import CorrectionNames from "../../update/correction_names";

import ScholarshipLayout from "../../generate-internship/generate-internship";
import ChangeInternshipLayout from "../../change-intership/change-internship";
import UploadInternShip from "../../upload-internship/upload-intership";
import CreateInternship from "../../create-internship/Create-internship";
import IdentityLogin from "../../id-card";

const PublicRoute = (props) => {
  return (
    <div>
      {/*<LoaderProgressBar />*/}
      <Header />
      <HeaderSticky />
      <Overlay />

      <main>
        <Routes>
          <Route path={"/home"} element={<Home />} />
          <Route path={"/our-programs"} element={<OurProgram />} />
          <Route path={"/program/:slug"} element={<CourseDetail />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/tutor/:slug"} element={<StaffProfile />} />
          <Route path={"/programme-guide"} element={<AdmissionAndFees />} />
          <Route
            path={"/admission-letter/:ApplicationID"}
            element={<AdmissionLetter />}
          />
          <Route
            path={"/generate-internship"}
            element={<ScholarshipLayout />}
          />
          <Route
            path="/change-internship"
            element={<ChangeInternshipLayout />}
          />
          <Route path="/create-internship" element={<CreateInternship />} />
          <Route path="/upload-letter" element={<UploadInternShip />} />
          <Route path={"/news"} element={<News />} />
          <Route path={"/news/details/:slug"} element={<NewsDetails />} />
          <Route path={"/ideas-project"} element={<About />} />
          <Route
            path={"/implementation-team"}
            element={<ImplementationTeam />}
          />
          <Route path={"/team/:slug"} element={<TeamProfile />} />
          <Route path={"/validate/:slug"} element={<Validation />} />
          <Route path={"/student/login"} element={<StudentLogin />} />
          <Route path={"/student/class"} element={<Player />} />
          <Route path={"/student/id-card"} element={<IdentityLogin />} />
          {/*<Route*/}
          {/*  path={"/student/uploads-passport/:slug"}*/}
          {/*  element={<UploadPassport />}*/}
          {/*/>*/}
          <Route path={"/gallery"} element={<Gallery />} />

          <Route
            path={"/student/passport/:slug"}
            element={<UploadPassport />}
          />
          <Route
            path={"/student/apply-change-of-programme"}
            element={<ChangeProgramme />}
          />
          <Route
            path={"/student/correction-of-names"}
            element={<CorrectionNames />}
          />

          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};
export default PublicRoute;
