import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../url"; // Replace with your actual server link

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "40px auto",
    padding: "30px",
    backgroundColor: "#f8f9fa",
    borderRadius: "15px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    fontSize: "2.5em",
    color: "#2c3e50",
    textAlign: "center",
    marginBottom: "30px",
  },
  input: {
    width: "100%",
    padding: "12px",
    border: "1px solid #bdc3c7",
    borderRadius: "5px",
    fontSize: "16px",
    marginBottom: "16px",
  },
  button: {
    backgroundColor: "#3498db",
    color: "white",
    padding: "12px 20px",
    border: "none",
    borderRadius: "5px",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "24px",
    borderRadius: "8px",
    maxWidth: "400px",
    width: "100%",
  },
  modalTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  modalButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modalButton: {
    padding: "8px 16px",
    marginLeft: "8px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
  cancelButton: {
    backgroundColor: "#ccc",
  },
  confirmButton: {
    backgroundColor: "#333",
    color: "white",
  },
};

const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Federal Capital Territory",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Others",
];

export default function InternShipForm() {
  const [formData, setFormData] = useState({
    email: "",
    studentId: "",
    phoneNumber: "",
    courseTaken: "",
    hasPreferredPlace: null,
    preferredState: "",
    address: "",
    companyName: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const confirmSubmit = async () => {
    setIsLoading(true);
    const updatedFormData = {
      ...formData,
      hasPreferredPlace: formData.hasPreferredPlace === "yes" ? true : false,
    };

    try {
      const response = await fetch(`${serverLink}generate/createInternship`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(
          data.message || "Internship application submitted successfully!"
        );
        setTimeout(() => {
          setIsModalOpen(false);
          navigate("/generate-internship"); // Adjust this route as needed
        }, 1000);
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.message ||
            "An error occurred while submitting the application."
        );
        setIsModalOpen(false);
      }
    } catch (error) {
      toast.error("A network error occurred. Please try again later.");
      console.error(error);
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>IDEAS Internship Application</h1>
      <form onSubmit={handleSubmit} style={{ display: "grid", gap: "20px" }}>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email Address"
          style={styles.input}
          required
        />
        <input
          type="text"
          name="studentId"
          value={formData.studentId}
          onChange={handleInputChange}
          placeholder="Student ID"
          style={styles.input}
          required
        />
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          placeholder="Phone Number"
          style={styles.input}
          required
        />
        <select
          name="courseTaken"
          value={formData.courseTaken}
          onChange={handleInputChange}
          style={styles.input}
          required
        >
          <option value="">Select a course</option>
          <option value="Data Analytics">Data Analytics</option>
          <option value="Business Intelligence And Dashboard Creation">
            Business Intelligence And Dashboard Creation
          </option>
          <option value="Artificial Intelligence">
            Artificial Intelligence
          </option>
          <option value="Software Engineering">Software Engineering</option>
          <option value="Blockchain Technology">Blockchain Technology</option>
          <option value="Cybersecurity">Cybersecurity</option>
        </select>
        <label
          style={{
            display: "block",
            marginBottom: "5px",
            color: "#34495e",
            fontWeight: "bold",
          }}
        >
          Do you have a preferred place of internship?
        </label>
        <div style={{ display: "flex", gap: "20px" }}>
          <label
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <input
              type="radio"
              name="hasPreferredPlace"
              value="yes"
              checked={formData.hasPreferredPlace === "yes"}
              onChange={handleInputChange}
              style={{ marginRight: "8px" }}
            />
            Yes
          </label>
          <label
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <input
              type="radio"
              name="hasPreferredPlace"
              value="no"
              checked={formData.hasPreferredPlace === "no"}
              onChange={handleInputChange}
              style={{ marginRight: "8px" }}
            />
            No
          </label>
        </div>
        {formData.hasPreferredPlace === "yes" && (
          <>
            <select
              name="preferredState"
              value={formData.preferredState}
              onChange={handleInputChange}
              style={styles.input}
              required
            >
              <option value="">Select a state</option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address"
              style={styles.input}
            />
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              placeholder="Company Name"
              style={styles.input}
            />
          </>
        )}
        <button type="submit" style={styles.button}>
          Submit Application
        </button>
      </form>

      {isModalOpen && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h3 style={styles.modalTitle}>Warning</h3>
            <p>
              Please confirm you want to submit your internship application.
            </p>
            <div style={styles.modalButtons}>
              <button
                style={{ ...styles.modalButton, ...styles.cancelButton }}
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
              <button
                style={{ ...styles.modalButton, ...styles.confirmButton }}
                onClick={confirmSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
