import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { showAlert } from "../common/sweetalert/sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import {serverLink} from "../../components/url";

const Contact = (props) => {
    const [createItem, setCreateItem] = useState({
        EntryID: "",
        FullName: "",
        EmailAddress: "",
        Subject: "",
        Message: "",
        HasReplay: "",
        InsertedDate: "",
    });

    const resetItem = () => {
        setCreateItem({
            ...createItem,
            EntryID: "",
            FullName: "",
            EmailAddress: "",
            Subject: "",
            Message: "",
            HasReplay: "",
            InsertedDate: "",
        });
    };

    const onEdit = (e) => {
        setCreateItem({
            ...createItem,
            [e.target.id]: e.target.value,
        });
    };

    const onSubmit = async () => {
        for (let key in createItem) {
            if (
                createItem.hasOwnProperty(key) &&
                key !== "HasReplay" &&
                key !== "EntryID" &&
                key !== "InsertedDate"
            ) {
                if (createItem[key] === "") {
                    await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
                    return false;
                }
            }
        }

        if (createItem.EntryID === "") {
            await axios
                .post(`${serverLink}foundation/contact`, createItem)
                .then((result) => {
                    if (result.data.message === "success") {
                        showAlert("Success", "Message submitted successfully", "success");
                        resetItem();
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    };


    return (
        <>
            <Breadcrumb page_name={"Contact"} />

            {/*CONTACT FORM SECTION*/}
            <section className="contact__area pt-115 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-lg-6">
                            <div className="contact__wrapper">
                                <div className="section__title-wrapper mb-40">
                                    <h2 className="section__title">Get In<span className="yellow-bg yellow-bg-big"> Touch<img
                                        src="assets/img/shape/yellow-bg.png" alt="" /></span></h2>
                                    <p>Have a question or just want to say hi? We would love to hear from you.</p>
                                </div>
                                <div className="contact__form">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-xxl-6 col-xl-6 col-md-6">
                                                <div className="contact__form-input">
                                                    <input
                                                        type="text"
                                                        id="FullName"
                                                        className="form-control"
                                                        placeholder="Full Name"
                                                        value={createItem.FullName}
                                                        onChange={onEdit}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-md-6">
                                                <div className="contact__form-input">
                                                    <input type="email"
                                                           id="EmailAddress"
                                                           className="form-control"
                                                           placeholder="Email Address"
                                                           value={createItem.EmailAddress}
                                                           onChange={onEdit}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="contact__form-input">
                                                    <input type="text"
                                                           id="Subject"
                                                           className="form-control"
                                                           placeholder="Subject"
                                                           value={createItem.Subject}
                                                           onChange={onEdit}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="contact__form-input">
                                                    <textarea
                                                        id="Message"
                                                        className="form-control"
                                                        value={createItem.Message}
                                                        onChange={onEdit}
                                                        placeholder="Enter Your Message"></textarea>
                                                </div>
                                            </div>

                                            <div className="col-xxl-12">
                                                <div className="contact__btn">
                                                    <button className="tp-btn"
                                                            type="button"
                                                            style={{ backgroundColor: "#63656a" }}
                                                            onClick={onSubmit}
                                                    >Send your message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
                            <div className="contact__info white-bg p-relative z-index-1">
                                <div className="contact__shape">
                                    <img className="contact-shape-1" src="assets/img/contact/contact-shape-1.png"
                                         alt="" />
                                        <img className="contact-shape-2" src="assets/img/contact/contact-shape-2.png"
                                             alt="" />
                                            <img className="contact-shape-3" src="assets/img/contact/contact-shape-3.png" alt="" />
                                </div>
                                <div className="contact__info-inner white-bg">
                                    <ul>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="map" viewBox="0 0 24 24">
                                                        <path className="st0"
                                                              d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z"/>
                                                        <circle className="st0" cx="12" cy="10" r="3"/>
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>Baze University</h4>
                                                    <p><a target="_blank"
                                                          href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873">
                                                      Room A22 Baze University, Abuja </a></p>

                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="mail" viewBox="0 0 24 24">
                                                        <path className="st0"
                                                              d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z"/>
                                                        <polyline className="st0" points="22,6 12,13 2,6 "/>
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>Email Us Directly</h4>
                                                    <p><a href="mailto:mailto:ideas@bazeuniversity.edu.ng">ideas@bazeuniversity.edu.ng</a></p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact__info-item d-flex align-items-start mb-35">
                                                <div className="contact__info-icon mr-15">
                                                    <svg className="call" viewBox="0 0 24 24">
                                                        <path className="st0"
                                                              d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z"/>
                                                    </svg>
                                                </div>
                                                <div className="contact__info-text">
                                                    <h4>Phone</h4>
                                                    <p><a href="tel:(+234) 813 546 2844">(+234)  7043173822</a></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="contact__social pl-30">
                                        <h4>Follow Us</h4>
                                        <ul>
                                            <li><a  target="_blank" href='https://www.facebook.com/BazeUniversity' className="fb"><i
                                                className="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a target="_blank" href='https://twitter.com/Baze_University' className="tw"><i className="fa-brands fa-twitter"></i></a>
                                            </li>
                                            <li><a target="_blank" href='https://www.linkedin.com/school/bazeuniversity/mycompany/' className="pin"><i className="fa-brands fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/*CONTACT INFO SECTION*/}
            <section className="contact__area grey-bg-2 pt-120 pb-90 p-relative fix">
                <div className="contact__shape">
                    <img className="contact-shape-5" src="assets/img/contact/contact-shape-5.png" alt="" />
                        <img className="contact-shape-4" src="assets/img/contact/contact-shape-4.png" alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-5 offset-lg-1 col-md-6">
                            <div className="contact__item text-center mb-30 transition-3 white-bg">
                                <div className="contact__icon d-flex justify-content-center align-items-end">
                                    <svg viewBox="0 0 24 24">
                                        <circle className="st0" cx="12" cy="12" r="10"/>
                                        <path className="st0" d="M8,14c0,0,1.5,2,4,2s4-2,4-2"/>
                                        <line className="st0" x1="9" y1="9" x2="9" y2="9"/>
                                        <line className="st0" x1="15" y1="9" x2="15" y2="9"/>
                                    </svg>
                                </div>
                                <div className="contact__content">
                                    <h3 className="contact__title">Programme</h3>
                                    <p>Our programme enrolment is open for all gender, and age range.</p>
                                    <Link className="tp-btn tp-btn-border" to={"/our-programs"}>Explore our programme</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5  col-lg-5 col-md-6">
                            <div className="contact__item text-center mb-30 transition-3 white-bg">
                                <div className="contact__icon d-flex justify-content-center align-items-end">
                                    <svg viewBox="0 0 24 24">
                                        <path className="st0"
                                              d="M21,10.8c0,1.3-0.3,2.6-0.9,3.8c-1.4,2.9-4.4,4.7-7.6,4.7c-1.3,0-2.6-0.3-3.8-0.9L3,20.3l1.9-5.7  C4.3,13.4,4,12.1,4,10.8c0-3.2,1.8-6.2,4.7-7.6c1.2-0.6,2.5-0.9,3.8-0.9H13c4.3,0.2,7.8,3.7,8,8V10.8z"/>
                                        <g>
                                            <circle className="st1" cx="9.3" cy="10.5" r="0.5"/>
                                            <circle className="st1" cx="12.5" cy="10.5" r="0.5"/>
                                            <circle className="st1" cx="15.7" cy="10.5" r="0.5"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className="contact__content">
                                    <h3 className="contact__title">Online Support</h3>
                                    <p>
                                        You can connect with us via our email for more information.
                                    </p>
                                    <a  href="mailto:shortcourse@bazeuniversity.edu.ng" className="tp-btn tp-btn-border " >Connect</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Contact;
