import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import { serverLink} from "../../components/url";
import {showAlert} from "../../components/common/sweetalert/sweetalert";
import axios from "axios";
import { toast } from 'react-toastify';
import banner from "../../images/login_pics-min.png";
import {setAttendanceID, setHomeReport, setStudentData, setTimestamp} from "../../action/actions";

const StudentLogin = (props) => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [IsLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        Username: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.Username.toString().trim() === "") {
            toast.error("Please enter your email address");
            return false;
        }

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}foundation/student/login`, formData)
            .then( async (result) => {
                const student_data = result.data.userData;
                const response = result.data.message;
                if (response === 'success') {
                    toast.success("Your are successfully logged in");
                    // props.setOnStudentData(student_data)
                    setTimeout(()=>{
                        window.location.href = student_data[0]?.ClassLink
                        // navigate('/student/class');
                    }, 2000)
                    setFormData({
                        ...formData,
                        Username: "",
                    })
                    setIsLoading(false)
                } else if (response === 'inactive') {
                    toast.error("Class is not active, please try again later!");
                } else if (response === 'invalid_login') {
                    toast.error("Invalid login credentials, please try again!");
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            }).catch(error => {
            showAlert(
                "NETWORK ERROR",
                "Please check your network connection and try again!",
                "error"
            );
            setIsLoading(false)
        })
    }

    useEffect(() => {
        props.setOnStudentData([])
        props.setOnAttendanceID('0')
        props.setOnTimestamp('0')
        localStorage.setItem('attendance_id', '0');
        localStorage.setItem('timestamp', 0);
    }, [])

    return (
        <>
            <section className="teacher__area mb-5">
                <div className="container">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-6 hide-mobile-view" style={{background: `url(${banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '700px', display: 'none'}}></div>
                            <div className="col-md-6"  style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px'}}>
                                <div className=" row d-flex justify-content-center align-items-center h-100">
                                    <div className="col-md-12 mt-5">
                                        <div className=" mb-2">
                                            <form className=" bg-white p-3">
                                                <div className="text-left mb-30">
                                                    <div className="section-title">
                                                        <h1 className="center-text" style={{textAlign: 'center'}}>Join Class</h1>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" id="Username" name="Username" value={formData.Username} onChange={handleChange} placeholder="Email Address"/><span
                                                    className="focus-border"></span></div>
                                                <button type="button" onClick={handleSubmit} className="btn btn-success mt-3 w-100">LOGIN
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStudentData: (p) => {
            dispatch(setStudentData(p))
        },
        setOnAttendanceID: (p) => {
            dispatch(setAttendanceID(p))
        },
        setOnTimestamp: (p) => {
            dispatch(setTimestamp(p))
        },
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(StudentLogin);