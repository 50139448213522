import React, {useState} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import {currencyConverter} from "../../url";
import {Link} from "react-router-dom";

const OurProgram = (props) => {
    const [courseList, setCourseList] = useState(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : []);

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value === '') {
            setCourseList(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [])
        } else {
            const course = typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [];
            if (course.length > 0) {
                setCourseList(course.filter(item => item.CourseName.toLowerCase().includes(value.toLowerCase())))
            } else{
                setCourseList([])
            }
        }
    }

    const handleDepartmentFilter = (department_id) => {
        if (department_id === 'all') {
            setCourseList(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [])
        } else {
            const course = typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [];
            setCourseList(course.filter(item => item.DepartmentID === parseInt(department_id)))
        }

    }

    const handleModeOfTeachingFilter = (mode_of_teaching) => {
        if (mode_of_teaching === 'all') {
            setCourseList(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [])
        } else {
            const course = typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : [];
            setCourseList(course.filter(item => item.ModeOfTeaching.toLowerCase() === mode_of_teaching))
        }

    }

    return <main>
        <Breadcrumb page_name={"Our Programs"}/>

        <section className="course__area pt-10 pb-90 grey-bg-3">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8">

                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="course__tab-conent">
                                    <div className="tab-content" id="courseTabContent">

                                        <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                             aria-labelledby="grid-tab">
                                            <div className="row">
                                                {
                                                    courseList.length > 0 ?
                                                    courseList.map(course => {
                                                        return <div key={course.EntryID}
                                                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                                            <div className="course__item white-bg transition-3 mb-30">
                                                                <div className="course__content p-relative">
                                                                    <div className="course__price-2">
                                                                        <span>
                                                                            Free
                                                                            {/*{currencyConverter(course.Price)}*/}
                                                                        </span>
                                                                    </div>
                                                                    <div className="course__tag">
                                                                        <Link to={"#"}>{course.DepartmentName}</Link>
                                                                    </div>
                                                                    <h3 className="course__title">
                                                                        <Link to={`/program/${course.Slug}`}>
                                                                            {course.CourseName}
                                                                        </Link>
                                                                    </h3>

                                                                    <div
                                                                        className="course__bottom d-sm-flex align-items-center justify-content-between">
                                                                        <div className="course__tutor">
                                                                            <Link to={"#"}>{course.ModeOfTeaching}</Link>
                                                                        </div>
                                                                        <div className="course__lesson">
                                                                            <Link to={`/program/${course.Slug}`}>
                                                                                <svg width="14" height="16"
                                                                                     viewBox="0 0 14 16" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M1 12.2V4.49999C1 1.7 1.70588 1 4.52941 1H9.47059C12.2941 1 13 1.7 13 4.49999V11.5C13 11.598 13 11.696 12.9929 11.794"
                                                                                        stroke="#49535B"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"></path>
                                                                                    <path
                                                                                        d="M3.01176 10.0999H13V12.5498C13 13.9008 11.8918 14.9998 10.5294 14.9998H3.47059C2.10824 14.9998 1 13.9008 1 12.5498V12.0948C1 10.9959 1.90353 10.0999 3.01176 10.0999Z"
                                                                                        stroke="#49535B"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"></path>
                                                                                    <path d="M4.17647 4.5H9.82353"
                                                                                          stroke="#49535B"
                                                                                          strokeLinecap="round"
                                                                                          strokeLinejoin="round"></path>
                                                                                    <path d="M4.17647 6.94995H7.70589"
                                                                                          stroke="#49535B"
                                                                                          strokeLinecap="round"
                                                                                          strokeLinejoin="round"></path>
                                                                                </svg>
                                                                                Read More
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) : <div className="alert alert-danger">NO PROGRAM FOUND!</div>
                                                }


                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4">
                        <div className="course__sidebar pl-70">
                            {/*SEARCH START*/}
                            <div className="course__sidebar-widget white-bg">
                                <div className="course__sidebar-search">
                                    <input type="text" onChange={handleSearch} placeholder="Search for programs..."/>
                                    <button type="submit">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                             viewBox="0 0 584.4 584.4"
                                             style={{enablebackground: 'new 0 0 584.4 584.4'}}>
                                            <g>
                                                <g>
                                                    <path className="st0"
                                                          d="M565.7,474.9l-61.1-61.1c-3.8-3.8-8.8-5.9-13.9-5.9c-6.3,0-12.1,3-15.9,8.3c-16.3,22.4-36,42.1-58.4,58.4    c-4.8,3.5-7.8,8.8-8.3,14.5c-0.4,5.6,1.7,11.3,5.8,15.4l61.1,61.1c12.1,12.1,28.2,18.8,45.4,18.8c17.1,0,33.3-6.7,45.4-18.8    C590.7,540.6,590.7,499.9,565.7,474.9z"/>
                                                    <path className="st1"
                                                          d="M254.6,509.1c140.4,0,254.5-114.2,254.5-254.5C509.1,114.2,394.9,0,254.6,0C114.2,0,0,114.2,0,254.5    C0,394.9,114.2,509.1,254.6,509.1z M254.6,76.4c98.2,0,178.1,79.9,178.1,178.1s-79.9,178.1-178.1,178.1S76.4,352.8,76.4,254.5    S156.3,76.4,254.6,76.4z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {/*SEARCH ENDS*/}


                            <div className="course__sidebar-widget white-bg">
                                <div className="course__sidebar-info">
                                    <h3 className="course__sidebar-title">Departments</h3>
                                    <ul>
                                        <li>
                                            <div className="course__sidebar-check mb-10 d-flex align-items-center">
                                                <label className="m-check-label" onClick={() => handleDepartmentFilter('all')} htmlFor="m-all-course">All ({ typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course.length : 0 })</label>
                                            </div>
                                        </li>
                                        {
                                            typeof props.HomeReport.department !== 'undefined' && props.HomeReport.department.length > 0 &&
                                            props.HomeReport.department.map(department => {
                                                const course_count = props.HomeReport.course.filter(item => item.DepartmentID === department.EntryID).length
                                                return <li>
                                                        <div key={department.EntryID} className="course__sidebar-check mb-10 d-flex align-items-center">
                                                            <label className="m-check-label" onClick={() => handleDepartmentFilter(department.EntryID)} htmlFor="m-eng">{department.DepartmentName} ({course_count})</label>
                                                        </div>
                                                    </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="course__sidebar-widget white-bg">
                                <div className="course__sidebar-info">
                                    <h3 className="course__sidebar-title">Mode Of Teaching</h3>
                                    <ul>
                                        <li>
                                            <div className="course__sidebar-check mb-10 d-flex align-items-center">
                                                <label className="m-check-label" htmlFor="m-all-course" onClick={() => handleModeOfTeachingFilter('all')}>All ({ typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course.length : 0 })</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="course__sidebar-check mb-10 d-flex align-items-center">
                                                <label className="m-check-label" htmlFor="m-free" onClick={() => handleModeOfTeachingFilter('hybrid')}>Hybrid ({ typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course.filter(item => item.ModeOfTeaching.toLowerCase() === 'hybrid').length : 0 })</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
}
const mapStateToProps = (state) => {
    return {
        HomeReport: state.HomeReport
    };
};

export default connect(mapStateToProps, null)(OurProgram);
