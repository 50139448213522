import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { showAlert } from "../common/sweetalert/sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import {currencyConverter, serverLink} from "../../components/url";
import {setHomePageData} from "../../action/actions";
import shape1 from "../../images/shape/shape-1.png";
import shape2 from "../../images/shape/shape-2.png";

const StaffProfile = (props) => {
    const { slug } = useParams();
    const [staff] = useState(props.homePageData.tutor_list ? props.homePageData.tutor_list : null);
    const [modules] = useState(props.homePageData.tutor_enrol ? props.homePageData.tutor_enrol : null);

    const [selectedStaff, setSelectedStaff] = useState(
        staff.length > 0
            ? staff.filter(
                (item) => item.Slug === slug
            )
            : []
    );


    const [staffModule, setStaffModule] = useState(
        modules.length > 0
            ? modules.filter(
                (item) => item.StaffSlug === slug
            )
            : []
    );
    useEffect(() => {
        staff.push(staff.filter((item) => item.Slug === slug));
        setSelectedStaff(
            staff.length > 0
                ? props.homePageData.tutor_list.filter(
                    (item) => item.Slug === slug
                )
                : []
        );


        modules.push(modules.filter((item) => item.StaffSlug === slug));
        setStaffModule(
            modules.length > 0
                ? props.homePageData.tutor_enrol.filter(
                    (item) => item.StaffSlug === slug
                )
                : []
        );
    }, [slug]);


    return (
        <>
            <Breadcrumb page_name={"Tutor Profile"} />
            <section className="teacher__area pt-120 pb-110">
                <div className="page__title-shape">
                    <img className="page-title-shape-5 d-none d-sm-block"
                         src="assets/img/page-title/page-title-shape-1.png" alt="" />
                        <img className="page-title-shape-6" src="assets/img/page-title/page-title-shape-6.png" alt="" />
                            <img className="page-title-shape-3" src="assets/img/page-title/page-title-shape-3.png"
                                 alt="" />
                                <img className="page-title-shape-7" src="assets/img/page-title/page-title-shape-4.png"
                                     alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div className="teacher__details-thumb p-relative pr-30">
                                <img className="team-thumb" src={selectedStaff[0].ImageUrl} alt="" width="340px" height="400px"/>
                                    <div className="teacher__details-shape">
                                        <img className="teacher-details-shape-1"
                                             src={shape1} alt="" />

                                            <img className="teacher-details-shape-2"
                                                 src={shape2} alt="" />
                                    </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <div className="teacher__wrapper">
                                <div className="teacher__top d-md-flex align-items-end justify-content-between">
                                    <div className="teacher__info">
                                        <h4>{selectedStaff[0].StaffName}</h4>
                                        <span>{selectedStaff[0].EmailAddress}</span>
                                    </div>
                                    <div className="teacher__social-2">
                                        <h4>Follow Us:</h4>
                                        <ul>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].LinkedInUrl}>
                                                    <i className="fa-brands fa-linkedin" title={"LinkedIn"}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].ScholarUrl}>
                                                    <i className="fa-brands fa-google" title={"Google Scholar"}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].WebsiteUrl}>
                                                    <i className="fa-brands fa-internet-explorer" title={"Website Link"}></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/*BIO-DATA*/}
                                <div className="teacher__bio">
                                    <h3>Short Bio</h3>
                                    <p dangerouslySetInnerHTML={{__html: selectedStaff[0].Description}} style={{textAlign: "justify"}} />
                                </div>

                                {/*COURSES*/}

                                <div className="teacher__courses pt-55">
                                    <div className="section__title-wrapper mb-30">
                                        <h2 className="section__title">Tutor's Courses <span
                                            className="yellow-bg yellow-bg-big"></span></h2>
                                    </div>
                                    <div className="teacher__course-wrapper">
                                        <div className="row">
                                            {
                                                typeof props.homePageData.course !== "undefined" &&
                                                props.homePageData.course.length > 0 &&
                                                props.homePageData.course.map((course,index) => {
                                                    if (props.homePageData.tutor_enrol.filter(i => i.StaffID === selectedStaff[0].EntryID && i.CourseID === course.EntryID).length > 0)
                                                        return <div key={index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                                        <div className="course__item-2 transition-3 white-bg mb-30 fix">

                                                            <div className="course__content-2">
                                                                <div
                                                                    className="course__top-2 d-flex align-items-center justify-content-between">
                                                                    <div className="course__tag-2">
                                                                        <a href="#">{course.DepartmentName}</a>
                                                                    </div>
                                                                    {/* <div className="course__price-2">

                                                                        <span>{currencyConverter(course.Price)}</span>
                                                                    </div> */}
                                                                </div>
                                                                <h3 className="course__title-2">
                                                                    <Link to={`/program/${course.Slug}`}>
                                                                        {course.CourseName}
                                                                    </Link>
                                                                </h3>

                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffProfile);