import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import axios from "axios";
import { connect } from "react-redux";
import { serverLink } from "../../components/url";
import { setHomePageData } from "../../action/actions";
import shape1 from "../../images/shape/shape-1.png";
import shape2 from "../../images/shape/shape-2.png";
import placeholder from "../../images/user.jpg";

const TeamProfile = (props) => {
    const { slug } = useParams();
    if (slug === "") window.href = '/';
    const [selectedStaff, setSelectedStaff] = useState(props.homePageData.team.length > 0 ? props.homePageData.team.filter(x => x.EntryID.toString() === slug.toString()) : []);

    const getHomeReport = async () => {
        await axios.get(`${serverLink}foundation/home/report`)
            .then(result => {
                const data = (result.data);
                let team = data.team;
                setSelectedStaff(team.filter(item => item.EntryID.toString() === slug.toString()))
            })
            .catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        getHomeReport();
    }, [])

    return (
        <>
            <Breadcrumb page_name={"Implementation Team Profile"} />
            <section className="teacher__area pt-120 pb-110">
                <div className="page__title-shape">
                    <img className="page-title-shape-5 d-none d-sm-block"
                        src="assets/img/page-title/page-title-shape-1.png" alt="" />
                    <img className="page-title-shape-6" src="assets/img/page-title/page-title-shape-6.png" alt="" />
                    <img className="page-title-shape-3" src="assets/img/page-title/page-title-shape-3.png"
                        alt="" />
                    <img className="page-title-shape-7" src="assets/img/page-title/page-title-shape-4.png"
                        alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div className="teacher__details-thumb p-relative pr-30">
                                <img className="team-thumb" src={selectedStaff[0].ImageUrl ? `${serverLink}public/uploads/ideas_project/team/${selectedStaff[0].ImageUrl}` : placeholder} alt="" width="340px" height="400px" />
                                <div className="teacher__details-shape">
                                    <img className="teacher-details-shape-1"
                                        src={shape1} alt="" />

                                    <img className="teacher-details-shape-2"
                                        src={shape2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <div className="teacher__wrapper">
                                <div className="teacher__top d-md-flex align-items-end justify-content-between">
                                    <div className="teacher__info">
                                        <h4>{selectedStaff[0].StaffName}</h4>
                                        <span>{selectedStaff[0].EmailAddress}</span>
                                    </div>
                                    <div className="teacher__social-2">
                                        <h4>Follow Us:</h4>
                                        <ul>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].LinkedInUrl}>
                                                    <i className="fa-brands fa-linkedin" title={"LinkedIn"}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].ScholarUrl}>
                                                    <i className="fa-brands fa-google" title={"Google Scholar"}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].TwitterUrl}>
                                                    <i className="fa-brands fa-twitter" title={"Twitter Link"}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={selectedStaff[0].FacebookUrl}>
                                                    <i className="fa-brands fa-facebook" title={"Facebook Link"}></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/*BIO-DATA*/}
                                <div className="teacher__bio">
                                    <h3>Short Bio</h3>
                                    <p dangerouslySetInnerHTML={{ __html: selectedStaff[0].Bio }} style={{ textAlign: "justify" }} />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamProfile);