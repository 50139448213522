import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { serverLink } from "../url";
import { toast } from "react-toastify";

const styles = {
  container: {
    display: "flex",
    maxHeight: "100vh",
  },
  leftSection: {
    flex: 1,
    width: "50%",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "white",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "16px",
    textAlign: "center",
  },
  paragraph: {
    marginBottom: "24px",
    textAlign: "center",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    padding: "12px",
    backgroundColor: "#333",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  },
  letterContainer: {
    position: "relative",
    width: "794px",
    height: "1123px",
    backgroundColor: "white",
    fontFamily: "Arial, sans-serif",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  letterhead: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    position: "absolute",
    top: 120,
    left: 0,
    right: 0,
    bottom: 0,
    padding: "64px",
    zIndex: 1,
    textAlign: "justify",
  },
  "@media (max-width: 768px)": {
    container: {
      flexDirection: "column",
    },
    leftSection: {
      display: "none",
    },
    rightSection: {
      width: "100%",
      padding: "16px",
    },
    form: {
      width: "100%",
      maxWidth: "none",
    },
  },
};
const letterStyle = {
  fontFamily: "Arial, sans-serif",
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
  lineHeight: "1.6",
  color: "#333",
  fontSize: "16px",
};

const headerStyle = {
  display: "flex",
  alignItems: "",
  justifyContent: "space-between",
  marginBottom: "20px",
};

const addressStyle = {
  whiteSpace: "pre-line",
  lineHeight: "1.5",
  marginBottom: "20px",
  width: "300px",
  textWrap: "wrap",
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: "20px",
  textDecoration: "underline",
  textTransform: "uppercase",
};

const paragraphStyle = {
  marginBottom: "15px",
};

const signatureStyle = {
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const signatureImageStyle = {
  width: "150px",
  marginBottom: "10px",
};

const letterContentStyle = {
  textTransform: "none",
};

export default function ScholarshipLayout() {
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      console.log("Printing completed");
      setShouldPrint(false);
    },
  });

  useEffect(() => {
    if (shouldPrint && info) {
      handlePrint();
    }
  }, [shouldPrint, info]);

  const getDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${serverLink}generate/internshipletter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: email }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setInfo(data.data);
      return data;
    } catch (error) {
      setError(
        "An error occurred while generating the letter. Please try again."
      );
      console.error("Error:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateLetter = async () => {
    if (!email) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      toast.success("Please wait while the system is generating your letter");
      const result = await getDetails();

      if (result.message === "No preferred place found") {
        setError(
          "Sorry, it looks like you don't have a preferred place at the moment. Please exercise patience while we look for a suitable place for you. We'll keep you updated as soon as we find a match."
        );
      } else if (result.message === "Preferred place found") {
        setInfo(result.data);
        setShouldPrint(true);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error generating letter:", error);
      setError(
        "Sorry, it looks like you don't have a preferred place at the moment. Please exercise patience while we look for a suitable place for you. We'll keep you updated as soon as we find a match."
      );
    }
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.leftSection}>
          <img style={styles.img} src="/Rectangle.png" alt="Background" />
        </div>
        <div style={styles.rightSection}>
          <div style={styles.form}>
            <h2 style={styles.heading}>
              Yayy!! you've made it to the end of the programme
            </h2>
            <p style={styles.paragraph}>
              Fill in your details to generate your internship letter
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="EMAIL ADDRESS"
              style={styles.input}
            />
            <button
              style={styles.button}
              onClick={handleGenerateLetter}
              disabled={isLoading}
            >
              {isLoading ? "Generating..." : "Generate Letter"}
            </button>
            {error && (
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        {info && (
          <div ref={componentRef} style={styles.letterContainer}>
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/letterhead-1mM38lPqHyMZjL3bJJlJU5aKBTcQDQ.jpg"
              alt="Baze University Letterhead"
              style={styles.letterhead}
            />
            <div style={styles.content}>
              <div style={{ ...letterStyle, ...letterContentStyle }}>
                <div style={headerStyle}>
                  <div style={addressStyle}>
                    <span
                      style={{
                        display: "block",
                        fontWeight: "bold",
                      }}
                    >
                      {info.CompanyName.toUpperCase()},
                    </span>
                    {info.Address}
                    <br />
                    {info.PreferredStateOfInternship}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {new Date().toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                </div>

                <p style={{ fontWeight: "bold" }}>Dear Sir/Madam,</p>

                <div style={titleStyle}>
                  REQUEST FOR STUDENT INTERNSHIP PLACEMENT FOR {info.fullName}{" "}
                  (PROFESSIONAL DIPLOMA IN {info.CourseTaken.toUpperCase()})
                </div>

                <p style={paragraphStyle}>
                  Baze University, in partnership with Domineum, has trained
                  19,000 Nigerian youths with support from the World Bank and
                  the Ministry of Education. This training focuses on
                  21st-century industry skills such as Cybersecurity, Artificial
                  Intelligence, Blockchain Technology, Software Engineering, and
                  Data Analysis.
                </p>

                <p style={paragraphStyle}>
                  In line with the requirements of the training, participants
                  are to be placed in reputable organizations for internship
                  placements. This will provide them the opportunity to gain
                  first-hand practical experience in work culture and ethics.
                </p>

                <p style={paragraphStyle}>
                  This SIWES (Student Industrial Work Experience Scheme) will
                  run for six (6) months, from{" "}
                  <span style={{ fontWeight: "bold" }}>
                    25th September 2024 to 25th February 2025
                  </span>
                  . The student will be supervised at intervals during the
                  training to monitor progress. The University kindly solicits
                  your support in this regard.
                </p>

                <p style={paragraphStyle}>
                  Upon acceptance, we request that you kindly provide the
                  student with an acceptance letter.
                </p>

                <p style={paragraphStyle}>
                  Please accept the assurances of our highest regards.
                </p>

                <div style={signatureStyle}>
                  <img
                    src="/sign.png"
                    alt="Professor Peter Ogedebe Signature"
                    style={signatureImageStyle}
                  />
                  <p style={{ fontWeight: "bold", lineHeight: "100%" }}>
                    Professor Peter Ogedebe
                    <br />
                    <span style={{ fontWeight: "normal" }}>
                      Internship Coordinator
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
