import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {
    formatTime,
    getCurrentTime,
    getTimeInterval,
    serverLink,
    timeToSeconds
} from "../../components/url";
import axios from "axios";
import { toast } from 'react-toastify';
import YouTube from 'react-youtube';
import {setAttendanceID, setStudentData, setTimestamp} from "../../action/actions";
import {CirclesWithBar} from "react-loader-spinner";

const Player = (props) => {
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(true);
    const [seconds, setSeconds] = useState(0);
    const [IsLive, SetIsLive] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [student, setStudent] = useState({});
    const videoId = props.studentData[0]?.ClassLink;

    const opts = {
        height: '750',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const getStudentAttendance = async (id) => {
        setIsLoading(true)
        await axios.get(`${serverLink}foundation/student/attendance/${id}/${props.studentData[0]?.CourseID}`)
            .then(result => {
                let attendance = result.data.attendance;
                let student_course = result.data.course;
                let response = result.data.message;
                console.log(attendance)
                console.log(student_course)
                if (response === "success"){
                    if (attendance.length > 0){
                        if (student_course.length > 0) {
                            if (parseInt(student_course[0].IsLive) === 1) {
                                SetIsLive(true)
                                setIsActive(true)
                            } else {
                                SetIsLive(false)
                                setIsActive(false)
                            }
                        }
                        props.setOnAttendanceID(attendance[0].AttendanceID)
                        localStorage.setItem('attendance_id', attendance[0].AttendanceID);
                        props.setOnStudentData([{...props.studentData[0], AttendanceID: attendance[0].AttendanceID, TimeInterval: attendance[0].TimeInterval, IsLive: student_course[0].IsLive}])
                        let converted_time = timeToSeconds(attendance[0].TimeInterval)
                        const previousTime = converted_time;
                        localStorage.setItem('timestamp', parseInt(previousTime, 10));
                        setSeconds(parseInt(previousTime, 10));
                    }else{
                        if (student_course.length > 0){
                            if (parseInt(student_course[0].IsLive) === 1){
                                attendanceCheckIn(props.studentData[0])
                            }
                        }
                    }
                }
                setIsLoading(false)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const attendanceCheckIn = async (item) => {
        let sendData = {
            CourseID: item.CourseID,
            StudentID: item.StudentID,
            CheckIn: getCurrentTime(),
        }
        await axios.post(`${serverLink}foundation/student/attendance/checkin`, sendData)
            .then(result => {
                let attendance_id = result.data.entry_id;
                let response = result.data.message;
                if (response === "success"){
                    getStudentAttendance(attendance_id)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const attendanceCheckOut = async () => {
        const attendance_id = localStorage.getItem('attendance_id');
        const timestamp = localStorage.getItem('timestamp');

        let sendData = {
            CheckOut: getCurrentTime(),
            TimeInterval: formatTime(timestamp),
            AttendanceID: attendance_id,
        }
        await axios.patch(`${serverLink}foundation/student/attendance/checkout`, sendData)
            .then(result => {
                let response = result.data.message;
                if (response === "success"){
                    getStudentAttendance(attendance_id)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const onLogout = async (location) => {
        toast.info('Please wait...')
        await attendanceCheckOut()
        //Reset Page Data
        props.setOnStudentData([])
        props.setOnAttendanceID('0')
        props.setOnTimestamp('0')
        localStorage.setItem('attendance_id', '0');
        localStorage.setItem('timestamp', 0);
        setTimeout(()=>{
            if (location === "home"){
                navigate("/student/login")
            }else{
                navigate("/student/login")
            }
        }, 3000)

    }

    useEffect(() => {
        return () => {
            if (props.studentData.length < 1){
                navigate("/student/login")
            }else{
                setStudent(props.studentData[0])
                getStudentAttendance(props.studentData[0].AttendanceID)
            }
        }
    }, []);

    useEffect(() => {
        if (props.studentData.length < 1){
            navigate("/student/login")
        }else{
            if (parseInt(props.studentData[0].IsLive) === 1){
                SetIsLive(true)
            }else{
                SetIsLive(false)
                setIsLoading(false)
            }
        }
    }, [IsLive]);

    useEffect(() => {
        if (props.studentData.length > 0) {
            if (parseInt(props.studentData[0].IsLive) === 1) {
                let interval;
                if (isActive) {
                    interval = setInterval(() => {
                        setSeconds(prevSeconds => prevSeconds + 1);
                    }, 1000);
                }
                return () => clearInterval(interval);
            }
        }
    }, [isActive]);


    useEffect(() => {
        if (props.studentData.length > 0) {
            if (parseInt(props.studentData[0].IsLive) === 1) {
                localStorage.setItem('timestamp', seconds);
            }
        }
    }, [seconds]);

    useEffect(() => {
        // Load previous time from storage
        if (props.studentData.length > 0) {
            if (parseInt(props.studentData[0].IsLive) === 1) {
                getStudentAttendance(props.studentData[0].AttendanceID);
                let converted_time = timeToSeconds(props.studentData[0].TimeInterval)
                const previousTime = converted_time;
                props.setOnTimestamp(parseInt(previousTime, 10))
                setSeconds(parseInt(previousTime, 10));
            }
        }
    }, []);

    useEffect(() => {
        if (props.studentData.length > 0) {
            if (parseInt(props.studentData[0].IsLive) === 1) {
                const handleVisibilityChange = () => {
                    if (document.hidden) {
                        // Page is minimized or tab is switched
                        attendanceCheckOut()
                    } else {
                        // Page is visible
                        const attendance_id = localStorage.getItem('attendance_id');
                        getStudentAttendance(attendance_id);
                    }
                };

                const handleBeforeUnload = async (event) => {
                    event.preventDefault();
                    event.returnValue = "";
                   await attendanceCheckOut()

                    //Reset Page Data
                    props.setOnStudentData([])
                    props.setOnAttendanceID('0')
                    props.setOnTimestamp('0')
                    localStorage.setItem('attendance_id', '0');
                    localStorage.setItem('timestamp', 0);
                };

                document.addEventListener("visibilitychange", handleVisibilityChange);
                window.addEventListener("beforeunload", handleBeforeUnload);

                return () => {
                    document.removeEventListener("visibilitychange", handleVisibilityChange);
                    window.removeEventListener("beforeunload", handleBeforeUnload);
                };
            }else{
                const handleVisibilityChange = () => {
                    if (document.hidden) {
                        // Page is minimized or tab is switched
                    } else {
                        // Page is visible
                        getStudentAttendance(props.studentData[0].AttendanceID)
                    }
                };

                const handleBeforeUnload = async (event) => {
                    event.preventDefault();
                    event.returnValue = "";
                }

                document.addEventListener("visibilitychange", handleVisibilityChange);
                window.addEventListener("beforeunload", handleBeforeUnload);

                return () => {
                    document.removeEventListener("visibilitychange", handleVisibilityChange);
                    window.removeEventListener("beforeunload", handleBeforeUnload);
                };

            }
        }
    }, []);

    if (props.studentData.length > 0){
        if (parseInt(props.studentData[0].IsLive) === 1) {
            window.addEventListener('beforeunload', function(event) {
                attendanceCheckOut()
                event.returnValue = '';
            });
        }
    }else{
        navigate("/student/login")
    }





    return ( IsLoading ? <CirclesWithBar
                height="100"
                width="100"
                color="#999999"
                wrapperStyle={{paddingTop: "300px", paddingLeft: "50%"}}
                wrapperClass=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel='circles-with-bar-loading'
            /> :
            IsLive ?
        <>
            <section className="teacher__area mb-5">
                <div className="container-fluid">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="row">
                            <YouTube videoId={videoId} opts={opts}/>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-9 mb-2">
                                            <div className="d-flex justify-content-start">
                                                <div className="header__login-icon mr-10 text-center">
                                                    <a href="#" style={{ textAlign: 'center', height: '50px', width: '50px', lineHeight: '45px', backgroundColor: '#EEEEEE'}}>
                                                        <svg width="20" height="20" viewBox="0 0 12 14" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5.99995 6.83333C7.61078 6.83333 8.91662 5.5275 8.91662 3.91667C8.91662 2.30584 7.61078 1 5.99995 1C4.38912 1 3.08328 2.30584 3.08328 3.91667C3.08328 5.5275 4.38912 6.83333 5.99995 6.83333Z"
                                                                stroke="#031220" strokeWidth="1.2" strokeLinecap="round"
                                                                strokeLinejoin="round"></path>
                                                            <path
                                                                d="M11.0108 12.6667C11.0108 10.4092 8.76497 8.58333 5.99997 8.58333C3.23497 8.58333 0.989136 10.4092 0.989136 12.6667"
                                                                stroke="#031220" strokeWidth="1.2" strokeLinecap="round"
                                                                strokeLinejoin="round"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div>
                                                    <h5 className="mt-1 mb-0 pb-0 text-secondary">{props.studentData[0]?.StudentName}</h5>
                                                    <div className="text-muted mt-0 pt-0" style={{fontSize: '12px', marginTop: '-15px'}}>{props.studentData[0]?.EmailAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h3>Course: {props.studentData[0]?.CourseName}</h3>
                                        <div style={{textAlign: 'justify'}}>
                                            <p dangerouslySetInnerHTML={{__html: props.studentData[0]?.Overview}}></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="bg-secondary text-center d-flex justify-content-center align-items-center" style={{borderRadius: '5px', color: '#FFFFFF', fontSize: '25px', height: '50px'}}>
                                        {formatTime(seconds)}
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="alert alert-info">
                                            Please remember to always utilize the logout button to record your attendance before closing the browser
                                        </div>
                                        <button className="btn btn-primary btn-sm form-control" onClick={()=>onLogout('login')}>Logout</button>
                                    </div>
                                </div>
                                {/*<div className="col-md-4 m-0">*/}
                                {/*    <iframe src={props.studentData[0]?.CommentLink} height="100%" width="100%" frameBorder="0" style={{minHeight: '560px'}} title="Slido"></iframe>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </> :
                <div className="col-md-10 offset-sm-1 error-box " style={{marginTop: '30px', marginBottom: '50px'}}>
                    <div className="error-body text-center">
                        <div className="card">
                            <div className="card-body d-flex justify-content-center align-items-center text-center" style={{height: '400px'}}>
                                <div>
                                    {/*<h1 className="error-title">OOPS!</h1>*/}
                                    <span className=" fa fa-info-circle fa-5x mb-3 text-warning"></span>
                                    <h3 className=" error-subtitle">Class hasn't begun yet. Stay tuned for updates.</h3>
                                    <button  onClick={()=>onLogout('home')} className="btn btn-success btn-rounded waves-effect waves-light m-b-40 mt-2">Back to home</button> </div>
                                </div>
                            </div>
                        </div>
                </div>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
        studentData: state.StudentData,
        attendanceID: state.AttendanceID,
        timestamp: state.Timestamp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnStudentData: (p) => {
            dispatch(setStudentData(p))
        },
        setOnAttendanceID: (p) => {
            dispatch(setAttendanceID(p))
        },
        setOnTimestamp: (p) => {
            dispatch(setTimestamp(p))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);