import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {currencyConverter, formatDateAndTime} from "../url";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {serverLink} from "../url";

const NewsDetails = (props) => {
    const navigate = useNavigate();
    const {slug} = useParams();
    const [newsList, setNewsList] = useState(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : []);
    const [isLoading, setIsLoading] = useState(true);
    const [newsDetails, setNewsDetails] = useState({});
    const [imageList, setImagesList] = useState([]);

    const getData = async () => {
        let new_id = slug;
        await axios.get(`${serverLink}foundation/news/list`)
            .then((res) => {
                if (res.data.news.length > 0){
                    const record = res.data.news;
                    let details = record.filter(e=>e.Slug.toString()  === new_id.toString())
                    let other_news = record.filter(e=>e.Slug.toString()  !== new_id.toString())
                    if (details.length > 0){
                        setNewsDetails(details[0]);
                        setNewsList(other_news);
                        updateViewCount(details[0].EntryID)
                    }else{
                        navigate(`/news`)
                    }
                }else{
                    navigate(`/news`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const updateViewCount = async (id) => {
        const sendData = {NewsID: id};
        await axios.patch(`${serverLink}foundation/news/update/view-count`, sendData)
            .then((result) => {}).catch((err) => {console.log("NETWORK ERROR");});
    };


    useEffect(() => {
        if (!slug){
            navigate("/news")
        }
        getData()
    }, [window.location.href]);

    return <main>
        <Breadcrumb page_name={newsDetails.Title}/>

        <section className="course__area pt-10 pb-90 grey-bg-3">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8">

                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="course__tab-conent">
                                    <div className="tab-content" id="courseTabContent">

                                        <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                             aria-labelledby="grid-tab">
                                            <div className="row">
                                                <div className="course__description" style={{textAlign: 'justify'}}>
                                                    <div className="blog mb-3">
                                                        <div className="blog-image">
                                                             <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '60%'}} src={`${serverLink}public/uploads/ideas_project/news/${newsDetails?.ImagePath}`} alt=""/>
                                                        </div>
                                                    </div>
                                                    <h5 className="blog-title mb-3" dangerouslySetInnerHTML={{__html: newsDetails?.Title }}/>
                                                    <div className="blog-meta mb-0">
                                                        <ul className="list-unstyled mb-3 d-flex align-items-center justify-content-between">
                                                            <li><i className="fas fa-calendar-alt"/> {formatDateAndTime(newsDetails?.InsertedDate, "date")}</li>
                                                            <li><i className="fas fa-user"/> {newsDetails?.InsertedBy}</li>
                                                        </ul>
                                                    </div>
                                                    <hr/>
                                                    <div className="mb-2" dangerouslySetInnerHTML={{__html: newsDetails.Description}}/>
                                                    <hr/>
                                                    <div className="blog-meta mb-0">
                                                        <ul className="list-unstyled mb-3 d-flex align-items-center justify-content-between">
                                                            <li><i className="fas fa-eye"/> {newsDetails?.ViewCount}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4">
                        <div className="course__sidebar  p-relative">
                            <div className="course__sidebar-widget-2 white-bg mb-20">
                                <div className="course__sidebar-course">
                                    <h3 className="course__sidebar-title">Related News</h3>
                                    <hr/>
                                    <ul>
                                        {
                                            newsList.length > 0 &&
                                            newsList.map((item, index) => {
                                                if (index < 4) {
                                                        return <li key={index}>
                                                            <Link to={`/news/details/${item.Slug}`}>
                                                            <div className="course__sm d-flex align-items-center mb-1">
                                                                <img className="img-fluid border-radius img-thumbnail " style={{height: '80px', width: '100px', marginRight: '8px'}} src={`${serverLink}public/uploads/ideas_project/news/${item?.ImagePath}`} alt=""/>
                                                                <div className="course__sm-content">
                                                                        <Link to={`/news/details/${item.Slug}`}>{item.Title}</Link>
                                                                </div>
                                                            </div>
                                                            <div className="text-dark fw-bold">
                                                                        <span>
                                                                            {formatDateAndTime(item?.InsertedDate, "date")}
                                                                        </span>
                                                            </div>
                                                            <hr/>
                                                            </Link>
                                                        </li>

                                                }
                                            })
                                        }


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    </main>
}
const mapStateToProps = (state) => {
    return {
        HomeReport: state.HomeReport
    };
};

export default connect(mapStateToProps, null)(NewsDetails);
