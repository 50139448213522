import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    AttendanceID,
    HomePageDataReducer, HomeReportReducer, StudentDataReducer, Timestamp
} from "./reducers";
import { shortCode } from "../components/url";

const rootReducer = combineReducers({
    HomePageDataReducer: HomePageDataReducer,
    HomeReport: HomeReportReducer,
    StudentData: StudentDataReducer,
    AttendanceID: AttendanceID,
    Timestamp: Timestamp,
});

const persistConfig = {
    key: shortCode,
    storage,
};

export default persistReducer(persistConfig, rootReducer);
