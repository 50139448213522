import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { connect } from "react-redux";
import {encryptData, serverLink} from "../../components/url";
import placeholder from "../../images/user.jpg";

const ImplementationTeam = (props) => {
    const [teamList, setTeamList] = useState(typeof props.homePageData.team !== 'undefined' ? props.homePageData.team : []);
    return (
        <>
            <Breadcrumb page_name={"Implementation Team"} />
            <section className="teacher__area pt-120 pb-110">
                {/* <div className="page__title-shape">
                    <img className="page-title-shape-5 d-none d-sm-block"
                        src="assets/img/page-title/page-title-shape-1.png" alt="" />
                    <img className="page-title-shape-6" src="assets/img/page-title/page-title-shape-6.png" alt="" />
                    <img className="page-title-shape-3" src="assets/img/page-title/page-title-shape-3.png"
                        alt="" />
                    <img className="page-title-shape-7" src="assets/img/page-title/page-title-shape-4.png"
                        alt="" />
                </div> */}
                <div className="container">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="row">
                            <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                aria-labelledby="grid-tab">
                                <div className="row">

                                    {
                                        teamList !== 'undefined' && teamList.length > 0 &&
                                        teamList.map((team, index) => {
                                            return (
                                                <div key={index} className=" col-xxl-4 col-xl-4 col-lg-4 col-md-4 speaker-item wow fadeInLeft mb-20 row" data-wow-duration="1s" data-wow-delay=".1s">
                                                    <Link to={`/team/${team.EntryID}`} className="w-100">
                                                        <div className="align-items-center">
                                                            <img className="item-thumb mb-10" src={team.ImageUrl ? `${serverLink}public/uploads/ideas_project/team/${team.ImageUrl}` : placeholder} alt={team.FullName} width="340px" height="400px" />

                                                            <div className="course__member-name text-center">
                                                                <h5><Link to={`/team/${team.EntryID}`}>{team.FullName}</Link></h5>
                                                                <span style={{ fontWeight: 'bold' }}>{team.Designation}</span>
                                                                <br />
                                                                <span>{team.EmailAddress}</span>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </div>
                                            )

                                        })
                                    }

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};


export default connect(mapStateToProps, null)(ImplementationTeam);