import gallery1 from '../gallery/img/IMG_1893.JPG'
import gallery2 from '../gallery/img/IMG_1899.JPG'
import gallery3 from '../gallery/img/IMG_1900.JPG'
import gallery4 from '../gallery/img/IMG_1901.JPG'
import gallery5 from '../gallery/img/IMG_1904.JPG'
import gallery6 from '../gallery/img/img (1).jpeg'
import gallery7 from '../gallery/img/img (2).jpeg'
import gallery8 from '../gallery/img/img (3).jpeg'
import gallery9 from '../gallery/img/img (4).jpeg'
import gallery10 from '../gallery/img/img (5).jpeg'
import gallery11 from '../gallery/img/img (6).jpeg'
import gallery12 from '../gallery/img/img (7).jpeg'
import gallery13 from '../gallery/img/img (8).jpeg'
import gallery14 from '../gallery/img/img (9).jpeg'
import gallery15 from '../gallery/img/img (10).jpeg'
import gallery16 from '../gallery/img/img (11).jpeg'



export const gallery_images_data = [
    { images: gallery1 },
    { images: gallery2 },
    { images: gallery3 },
    { images: gallery4 },
    { images: gallery5 },
    { images: gallery6 },
    { images: gallery7 },
    { images: gallery8 },
    { images: gallery9 },
    { images: gallery10 },
    { images: gallery11 },
    { images: gallery12 },
    { images: gallery13 },
    { images: gallery14 },
    { images: gallery15 },
    { images: gallery16 },

];