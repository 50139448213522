import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { serverLink } from "../../components/url";
import { showAlert } from "../../components/common/sweetalert/sweetalert";
import axios from "axios";
import { toast } from "react-toastify";

const Validation = (props) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [IsLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    APPID: "",
    NIN: "",
  });

  const getAppID = () => {
    try {
      if (slug === "") {
        navigate("/");
      }
      return atob(slug);
    } catch (error) {
      console.error("Error decoding Base64 string:", error.message);
      navigate("/");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.APPID.toString().trim() === "") {
      toast.error("Applicant ID can not be empty");
      return false;
    }

    if (formData.NIN.toString().trim() === "") {
      toast.error("Please enter your NIN number");
      return false;
    }

    const sendData = { ...formData };
    toast.info("Please wait...");
    setIsLoading(true);
    axios
      .post(`${serverLink}foundation/validate`, sendData)
      .then(async (result) => {
        const response = result.data.message;
        if (response === "success") {
          toast.success("Your record has been successfully validated");
          setFormData({
            ...formData,
            NIN: "",
          });
          setIsLoading(false);
        } else if (response === "validated") {
          toast.error("Your record has already been validated");
        } else if (response === "invalid") {
          toast.error("Invalid Applicant");
        } else {
          showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
        }
      })
      .catch((error) => {
        showAlert(
          "NETWORK ERROR",
          "Please check your network connection and try again!",
          "error"
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    try {
      if (slug === "") {
        navigate("/");
      }
      setFormData({ ...formData, APPID: getAppID() });
    } catch (error) {
      console.error("Error decoding Base64 string:", error.message);
      navigate("/");
    }
  }, [location]);

  return (
    <>
      <section className="teacher__area pt-120 pb-110">
        <div className="container">
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="row">
              <div
                className="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div className="row">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-8 text-center">
                        <div className="section-title">
                          <h2>Verify National Identification Number (NIN)</h2>
                          <p>Enter your NIN number to verify your identity</p>
                        </div>
                      </div>
                    </div>
                    <p>NIN validation process has ended.</p>
                    {/*<div className="row justify-content-center">*/}
                    {/*    <div className="col-lg-7 mb-2">*/}
                    {/*        <form className="login bg-white shadow p-5">*/}
                    {/*            <div className="form-group"><label>NIN Number</label><input type="text" className="form-control" id="NIN" name="NIN" maxLength={11} value={formData.NIN} onChange={handleChange} placeholder="National Identification Number (NIN)"/><span*/}
                    {/*                className="focus-border"></span></div>*/}
                    {/*            <button type="button" onClick={handleSubmit} className="btn btn-success mt-3 w-100">VERIFY*/}
                    {/*            </button>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    homePageData: state.HomeReport,
  };
};

export default connect(mapStateToProps, null)(Validation);
