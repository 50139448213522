import BgImage from "../../images/logo_three.png";
const TermsAndConditions = (props) => {
    return(
        <div>
            {
                props.data.length > 0 &&
                <div style={{
                    backgroundImage: `url(${BgImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}>
                    <div className="bg body" style={{ paddingBottom: '5px' }} ref={props.componentRef} >
                        <div className="table-wrapper" >
                            <br />
                            <br />
                            <div>
                                <div className="title"> IDEAS Program Terms and Conditions </div>
                            </div>
                            <br />
                            <div style={{ marginTop: "-0px" }} >
                                <ol type="A">
                                    <li><strong>Admission Requirements:</strong> This is a provisional offer of admission and shall be confirmed subject to meeting the listed requirements in your offer letter.</li>
                                    <li><strong>Commencement Date:</strong> The IDEAS Professional Diploma program will commence on 1st March 2024 and will run for 1 Year (6 Months of Training & 6 Months of Internship). Commencement details and classroom invitations will be shared with all registered participants. If there is a schedule change, the same will be communicated via email or other communication channels.</li>
                                    <li><strong>Financial Obligations:</strong> The IDEAS program is free but participants are responsible for meeting any financial obligations associated with the program. This may include transportation costs, technology costs, or other expenses.</li>
                                    <li><strong>Course Requirements:</strong> Participants must complete their specified modules to fulfil the requirements of their diploma of choice. These courses may include both online and in-person components, depending on the hybrid nature of the IDEAS program.</li>
                                    <li><strong>Assessment and Evaluation:</strong> Participants will be assessed based on their performance in quizzes, assignments, exams, capstone projects, or other assessments. The onboarding package will clearly outline and share the evaluation criteria and grading system.</li>
                                    <li><strong>Program Completion Guidelines:</strong> Participants must attend at least 80% of all classes/seminars or other activities, and achieve a minimum score of 45% in each module and an average score of 60% at the end of the course assessment.</li>
                                    <li><strong>Code of Conduct:</strong> Participants must adhere to the institution's code of conduct and academic integrity policies. This includes avoiding plagiarism, cheating, or any other form of academic dishonesty.</li>
                                    <li><strong>Digital Technologies:</strong> The IDEAS program involves both online and in-person components. Therefore, participants at home must have access to a reliable internet connection, computer/laptop, webcam, microphone, or other tools necessary for participating in online learning activities.</li>
                                    <li><strong>Support Services:</strong> For enrollment-related questions, participants can reach us at ideas@bazeuniversity.edu.ng or +234 (0) 704 317 3822. These communication channels will offer support services that may include academic advising, counselling, technical support, or access to resources such as libraries or online databases to assist participants throughout their academic journey.</li>
                                    {/*<li><strong>Changes and Updates:</strong> Participants should be aware that rules and regulations may change over time, and they are responsible for staying informed about any updates or modifications to the program requirements.</li>*/}
                                </ol>
                                <div style={{textAlign: "center"}}>Note: By accepting this offer, you agree to our terms and conditions.</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default TermsAndConditions
