import React from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { gallery_images_data} from './gallery_img'
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";

export default function Gallery () {

    let gallery_images = [];
    gallery_images_data.map((x => {
        gallery_images.push(
            {
                title: "IDEAS Gallery",
                img: x.images,
                imgThumbnail: x.images
            }
        )
    }))
    return (
        <div>
        <Breadcrumb page_name={"Gallery"} />
        <>
            

            <section className="faq__area pt-70 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>The IDEAS Gallery</h2>
                            <hr className="dotted light-grey" />
                        </div>
                    </div>
                    </div>

                <div className="container col-xxl-12 col-xl-12 col-lg-12">
                    <div className="row">
                        <div className="layout-col col2-50 layout-9 col-md-9">
                            <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="image-wrapper" style={{ width: "100%", borderRadius: '5px', minHeight: '30%', maxHeight: '30%' }}>
                                                    <MuiFbPhotoGrid
                                                        images={gallery_images} // require
                                                        reactModalStyle={{ overlay: { zIndex: 200 } }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
        </div>
    )
}
