import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoute from "./components/routers/public-routes/PublicRoute";
import { connect } from "react-redux";
import { CirclesWithBar } from "react-loader-spinner";
import { setHomeReport } from "./action/actions";
import axios from "axios";
import { serverLink } from "./components/url";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay } from "swiper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(props) {
  SwiperCore.use([Autoplay]);
  const [isLoading, setIsLoading] = useState(true);

  const getHomeReport = async () => {
    await axios
      .get(`${serverLink}foundation/home/report`)
      .then((result) => {
        props.setOnHomeReport(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getHomeReport().then((r) => {});
  }, []);

  return (
    <div className="app-admin-wrap layout-sidebar-large clearfix">
      {isLoading ? (
        <CirclesWithBar
          height="100"
          width="100"
          color="#999999"
          wrapperStyle={{ paddingTop: "300px", paddingLeft: "50%" }}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel="circles-with-bar-loading"
        />
      ) : (
        <>
          {" "}
          <Router>
            <PublicRoute />
          </Router>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // loginData: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomeReport: (p) => {
      dispatch(setHomeReport(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
