import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { serverLink } from "../url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const styles = {
  container: {
    display: "flex",
    maxHeight: "100vh",
  },
  leftSection: {
    flex: 1,
    width: "50%",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "white",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "16px",
    textAlign: "center",
  },
  paragraph: {
    marginBottom: "24px",
    textAlign: "center",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
  },
  button: {
    width: "100%",
    padding: "12px",
    backgroundColor: "#333",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  },
  letterContainer: {
    position: "relative",
    width: "794px",
    height: "1123px",
    backgroundColor: "white",
    fontFamily: "Arial, sans-serif",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    overflow: "hidden",
  },
  letterhead: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    position: "absolute",
    top: 120,
    left: 0,
    right: 0,
    bottom: 0,
    padding: "64px",
    zIndex: 1,
    textAlign: "justify",
  },
};

export default function ChangeInternshipLayout() {
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${serverLink}change/changeinternship`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: email,
          state,
          company,
          companyAddress,
        }),
      });

      if (response.status === 400) {
        throw new Error("Cannot change accepted internship");
      }

      if (response.status === 404) {
        // Redirect to the Google Form without throwing an error
        toast.error(
          "please wait while we redirect you to the form for your internship location"
        );
        setTimeout(
          () =>
            (window.location.href =
              "https://docs.google.com/forms/d/e/1FAIpQLSehatLE8AYnarhpX48f-Q-jOcKgAlDCEd3UFWsGi1kiTm6brw/viewform"),
          400
        );
        return; // Exit the function early
      }

      // Handle success case
      setIsLoading(false);
      toast.success("Internship location changed successfully!");
      setTimeout(() => navigate("/generate-internship"), 1000);
    } catch (error) {
      // Handle error case
      setIsLoading(false);
      setError(error.message);
      toast.error(error.message);
    }
  };
  return (
    <>
      <div style={styles.container}>
        <div style={styles.leftSection}>
          <img style={styles.img} src="/Rectangle.png" alt="Background" />
        </div>
        <div style={styles.rightSection}>
          <div style={styles.form}>
            <h2 style={styles.heading}>
              Fill in the form to change your internship location
            </h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                style={styles.input}
                required
              />
              <input
                type="text"
                defaultValue={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Company Name"
                style={styles.input}
                required
              />
              <select
                onChange={(e) => setState(e.target.value)}
                style={styles.input}
                required
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <input
                type="text"
                defaultValue={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                placeholder="Company Address"
                style={styles.input}
                required
              />
              <button style={styles.button} type="submit">
                {isLoading ? "Changing..." : "Change"}
              </button>
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Federal Capital Territory",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Others",
];
