import React, {useState} from "react";
import certificate from "../../images/about.png";
import slider1 from "../../images/slider/3/slider-1.jpg";
import online from "../../images/online.png";
import guide from "../../images/guideline.jpg";
import img1 from "../../images/list 1.png";
import img2 from "../../images/cv 1.png";
import img3 from "../../images/check-mark 1.png";
import img4 from "../../images/agreement 2.png";
import img5 from "../../images/login 1.png";
import img6 from "../../images/agreement 1.png";
import img7 from "../../images/certification 1.png";
import sdgs from "../../images/sdgslogo.png";
import img8 from "../../images/firework 1.png";
import Slider from "../common/slider/slider";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import "./style.css";
import arrayShuffle from 'array-shuffle';
import {currencyConverter, formatDateAndTime, serverLink} from "../url";

export const Guide = ({heading, caption, img}) => {
    return <div
        className="col-md-3 contact__info-item d-flex align-items-center text-center justify-content-center mb-60">
        <div className="contact__info-text ">
            <div className="contact__info-icon  mb-2">
                <img width={50} height={50} src={img}/>
            </div>
            <h4 style={{fontSize: '14px'}}>{heading}</h4>
            <p style={{fontSize: '12px'}}>{caption}</p>
        </div>
    </div>
}

const Home = (props) => {
    const [faq, setFAQ] = useState(typeof props.HomeReport.faq !== 'undefined' ? props.HomeReport.faq : []);
    const course_shuffle = typeof props.HomeReport.course !== "undefined" ? arrayShuffle(props.HomeReport.course) : [];

    const [courseList, setCourseList] = useState(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course : []);
    const [newsList, setNewsList] = useState(typeof props.HomeReport.news !== 'undefined' ? props.HomeReport.news : []);
    const h2Style = {
        borderBottom: '3px solid',
        backgroundImage: 'linear-gradient(to right, #303030 33%, transparent 33%)',
    };

    return (
        <>
            <Slider/>

            <main>
                <section className="faq__area pt-150 pb-130">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5">
                                <img className=" mb-3"
                                     src={certificate}
                                     alt="Image"
                                     style={{height: '380px', width: '380px'}}
                                />
                            </div>


                            <div className="col-xxl-7 col-xl-7 col-lg-7">
                                <div className="faq__wrapper ">
                                    <div className="section__title-wrapper mb-5">
                                        <h2 className="section__title section__title-44" style={{color: "#2E8B57"}}><b>About
                                            the Programme</b></h2>
                                    </div>
                                    <p style={{textAlign: 'justify'}}>
                                        This comprehensive Programme is strategically designed to empower Nigerian youths
                                        hailing from diverse backgrounds, arming them with the knowledge and skills
                                        necessary to not only excel in their professional lives but also to actively
                                        contribute to the socio-economic development of their respective
                                        communities. <br/>
                                        <br/>
                                        The Federal Government of Nigeria through Federal Ministry of Education has
                                        obtained a Credit to finance a new World Bank-supported project called:
                                        Innovation Development and Effectiveness in the Acquisition of Skills (IDEAS)
                                        Project aim at improving the country’s TVET System. The objective of IDEAS
                                        project is to improve skills acquisition in Nigeria using a comprehensive
                                        approach and addressing key aspects of the skills development delivery system.
                                        It leverages industry involvement for better labour market fit and crowding in
                                        of private resources in the formal training system.
                                    </p>

                                    <div className="faq__btn">
                                        <Link className="tp-btn-5 tp-btn-13" to="/ideas-project">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="certificate__area pb-120 pt-120  grey-bg-4">
                    <div className="container">
                        <div className="">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">

                                <div className="row">
                                    <div
                                        className="section__title-wrapper mb-90">
                                        <h2 className="section__title-4  d-sm-flex align-items-center justify-content-center"
                                            style={{color: "#2E8B57"}}><b>Application Guideline </b></h2>
                                        <small className="d-sm-flex align-items-center justify-content-center">To start
                                            your journey, please follow the steps outlined below</small>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="course__tab-conent">
                                            <div className="tab-content" id="courseTabContent">

                                                <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                                     aria-labelledby="grid-tab">
                                                    <div className="row">
                                                        <Guide heading="Visit our Featured Courses"
                                                               caption="Explore the list of available professional short courses."
                                                               img={img1}/>
                                                        <Guide heading="Application"
                                                               caption="Select the course you are interested in."
                                                               img={img2}/>
                                                        <Guide heading="Wait for Confirmation"
                                                               caption="Await an email or notification confirming your application."
                                                               img={img3}/>
                                                        <Guide heading="Accept Enrollment"
                                                               caption="If your selected course has limited seats, you may receive an offer of enrollment."
                                                               img={img4}/>
                                                        <Guide heading="Access Course Material"
                                                               caption="Once enrolled, you will receive access to the course materials, which may include videos, reading materials, or online modules."
                                                               img={img5}/>
                                                        <Guide heading="Learn and Complete"
                                                               caption="Engage in the course, study the materials, and complete any assignments or assessments."
                                                               img={img6}/>
                                                        <Guide heading="Certification"
                                                               caption="After successfully completing the course, you will receive a certification or course completion acknowledgment."
                                                               img={img7}/>
                                                        <Guide heading="Congratulations!!"
                                                               caption="You've successfully finished the course. If you wish to further your learning with a Bsc. degree, visit the bazeuniversity.edu.ng"
                                                               img={img8}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="certificate__area pb-120 pt-120">
                    <div className="container">
                        <div className="">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">

                                <div className="row">
                                    <div
                                        className="section__title-wrapper mb-30 d-sm-flex align-items-center justify-content-center">
                                        <h2 className="section__title-4" style={{color: "#2E8B57"}}><b>Featured
                                            Courses </b></h2>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="course__tab-conent">
                                            <div className="tab-content" id="courseTabContent">

                                                <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                                     aria-labelledby="grid-tab">
                                                    <div className="row">
                                                        {
                                                            courseList.length > 0 ?
                                                                courseList.slice(0, 3).map(course => {
                                                                    return <div key={course.EntryID}
                                                                                className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                                                        <div
                                                                            className="course__item white-bg transition-3 mb-30 shadow">
                                                                            <div className="course__content p-relative">
                                                                                <div className="course__price-2 mb-2">
                                                                                    <span>Free</span>
                                                                                </div>
                                                                                <h3 className="course__title">
                                                                                    <Link
                                                                                        to={`/program/${course.Slug}`}>
                                                                                        {course.CourseName}
                                                                                    </Link>
                                                                                </h3>

                                                                                <div
                                                                                    className="course__bottom d-sm-flex align-items-center justify-content-between">
                                                                                    <div className="course__tutor">
                                                                                        <Link
                                                                                            to={`/program/${course.Slug}`}
                                                                                            style={{
                                                                                                backgroundColor: '#2E8B57',
                                                                                                // backgroundColor: '#4f4b4b',
                                                                                                color: '#FFFFFF',
                                                                                                textAlign: 'center'
                                                                                            }}>&nbsp;&nbsp;&nbsp; Apply
                                                                                            Now</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }) : <div className="alert alert-danger">NO PROGRAM
                                                                    FOUND!</div>
                                                        }

                                                        <div
                                                            className="category__wrapper-3 pt-5 d-flex align-items-center justify-content-center pt-30">
                                                            <div className="category__more  pt-100">
                                                                <Link className="tp-btn-5 tp-btn-8"
                                                                      to={"/our-programs"}>View All Courses</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section className="faq__area pt-100 pb-130 grey-bg-2 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-7 col-xl-7 col-lg-7">
                                <div className="faq__wrapper">
                                    <div className="section__title-wrapper mb-5">
                                        <h3 className="section__title section__title-30" style={{color: "#2E8B57"}}><b>IDEAS'
                                            Strategic Emphasis on Sustainable Development Goals (SDGs)</b></h3>
                                    </div>
                                    <p style={{textAlign: 'justify'}}>
                                        IDEAS places its focus on advancing the Sustainable Development Goals (SDGs)
                                        through innovative strategies. The program aligns with various SDGs established
                                        by the United Nations, creating a direct impact in the following areas:
                                        <br/>
                                        <ul style={{ listStylePosition: 'inside',marginLeft: '10px', paddingLeft: '10px' }}>
                                            <li>
                                                Quality Education: IDEAS contributes to SDG 4 by providing affordable and high-quality vocational training for young Nigerians.
                                            </li>
                                            <li>
                                                Decent Work: Addressing SDG 8, IDEAS promotes full and productive employment opportunities for all youth.
                                            </li>
                                            <li>
                                                Reduced Inequalities: Actively working towards SDG 10, the Programme empowers individuals and fosters social inclusion, thereby reducing inequalities.
                                            </li>
                                            <li>
                                                Stronger Partnerships: IDEAS supports SDG 17 by fostering collaboration among public, private, and civil society organizations.
                                            </li>
                                        </ul>
                                        <br/>
                                        In essence, through active engagement with these SDGs, IDEAS plays a pivotal role in shaping a more inclusive, sustainable, and prosperous future for Nigeria.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5" style={{textAlign: 'center'}}>
                                <img className="mb-3" src={sdgs} alt="Image" style={{height: '360px', width: '360px', marginTop:'35px'}}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="certificate__area pb-120 grey-bg-1 pt-120">
                    <div className="container">
                        <div className="">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">

                                <div className="row">
                                    <div
                                        className="section__title-wrapper mb-30 d-sm-flex align-items-center justify-content-center">
                                        <h2 className="section__title-4 featured-news">Latest News </h2>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="course__tab-conent">
                                            <div className="tab-content" id="courseTabContent">

                                                <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                                     aria-labelledby="grid-tab">
                                                    <div className="row">
                                                        {
                                                            newsList.length > 0 ?
                                                                newsList.slice(0, 3).map((news, index) => {
                                                                    if (index < 4) {
                                                                        return <div key={news.EntryID}
                                                                                    className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                                                            <Link to={`/news/details/${news.Slug}`}>
                                                                                <div
                                                                                    className="course__item white-bg transition-3 mb-30 shadow">
                                                                                    <div className=" p-relative">
                                                                                        <div
                                                                                            className="course__price-2 mb-2">
                                                                                            <img
                                                                                                className="img-thumbnail  mb-3"
                                                                                                src={`${serverLink}public/uploads/ideas_project/news/${news.ImagePath}`}
                                                                                                alt="news image"
                                                                                                style={{
                                                                                                    height: '170px',
                                                                                                    width: '350px'
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{
                                                                                            height: '40px',
                                                                                            textAlign: 'justify'
                                                                                        }}>
                                                                                            <h5 className="news_title">
                                                                                                <Link
                                                                                                    to={`/news/details/${news.Slug}`}>
                                                                                                    {news.Title}
                                                                                                </Link>
                                                                                            </h5>
                                                                                        </div>
                                                                                        <div
                                                                                            className="course__bottom d-sm-flex align-items-center justify-content-between mt-3">
                                                                                            <div>
                                                                                                <i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(news.InsertedDate, 'date')}
                                                                                            </div>
                                                                                            <div>
                                                                                                <i className="fas fa-eye pr-1"></i> {news.ViewCount}
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr style={{
                                                                                            marginTop: '8px',
                                                                                            marginBottom: '8px',
                                                                                        }}/>

                                                                                        <div
                                                                                            className="course__bottom d-sm-flex align-items-center justify-content-between">
                                                                                            <div className="">
                                                                                                <Link
                                                                                                    to={`/news/details/${news.Slug}`}>Read
                                                                                                    More</Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                }) : <div className="alert alert-info text-center"><b>NO
                                                                    RECORD
                                                                    FOUND!</b></div>
                                                        }

                                                        <div
                                                            className="category__wrapper-3 pt-5 d-flex align-items-center justify-content-center pt-30">
                                                            <div className="category__more  pt-100">
                                                                <Link className="tp-btn-5 tp-btn-8" to={"/news"}>View
                                                                    All News</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        HomeReport: state.HomeReport
    };
};

export default connect(mapStateToProps, null)(Home);
