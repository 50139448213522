import axios from "axios";
const CryptoJS = require("crypto-js");

export const serverStatus = "Live";

export const serverLink =
  serverStatus === "Dev"
    ? "http://localhost:4480/"
    : "https://ideas.api.bazeuniversity.edu.ng:5001/";

export const paymentAPIKey =
  serverStatus === "Dev"
    ? "pk_test_4afdc4ff65ce2328e5dd6a678f78103e0fe32c42"
    : "pk_live_12317e78152cbe1f4fa00ce79b99f497c8e2b989";

export const shortCode = "BAZE_UNIV_SHORT_COURSE";
export const projectLogo = "https://bazeuniversity.edu.ng/images/logo.png";
export const bucketUrl =
  "https://storage.googleapis.com/conference-management-363620.appspot.com/";

export const generate_token = (length) => {
  let a = "1234567890".split("");
  let b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};

export const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const getTimeInterval = (startTime, endTime) => {
  console.error(startTime);
  console.error(endTime);
  // Parse the times into Date objects
  const [startHour, startMinute, startSecond] = startTime
    .split(":")
    .map(Number);
  const [endHour, endMinute, endSecond] = endTime.split(":").map(Number);

  const startDate = new Date();
  startDate.setHours(startHour);
  startDate.setMinutes(startMinute);
  startDate.setSeconds(startSecond);

  const endDate = new Date();
  endDate.setHours(endHour);
  endDate.setMinutes(endMinute);
  endDate.setSeconds(endSecond);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const formattedTimeDifference = `${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  return formattedTimeDifference;
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};
export function timeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
}

export function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

export const currentDate = `${year}-${month}-${day}`;

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};
export const projectCode = "IDEAS_PROJECT";

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};

export function encryptData(string) {
  let secret_key = projectCode;
  let secret_iv = projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

export function decryptData(string) {
  let secret_key = projectCode;
  let secret_iv = projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const sendMail = (receiver, subject, body) => {
  axios
    .post(`${serverLink}mail/send_mail`, {
      receiver: receiver,
      subject: subject,
      body: body,
    })
    .then((res) => {
      if (res.data.message === "success") {
      } else {
        console.log(res.data);
      }
    });
};

export function removeSpace(str) {
  str = str.replace(/\s/g, "");
  return str;
}

var th_val = ["", "thousand", "million", "billion", "trillion"];
var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function convertNumbertoWords(s) {
  s = s.toString();
  s = s.replace(/[\, ]/g, "");
  if (s != parseFloat(s)) return "not a number ";
  var x_val = s.indexOf(".");
  if (x_val == -1) x_val = s.length;
  if (x_val > 15) return "too big";
  var n_val = s.split("");
  var str_val = "";
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == "1") {
        str_val += tn_val[Number(n_val[i + 1])] + " ";
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + " ";
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + " ";
      if ((x_val - i) % 3 == 0) str_val += "hundred ";
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
      sk_val = 0;
    }
  }
  if (x_val != s.length) {
    var y_val = s.length;
    str_val += "point ";
    for (var i = x_val + 1; i < y_val; i++) str_val += dg_val[n_val[i]] + " ";
  }
  return str_val.replace(/\s+/g, " ");
}

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function isWhiteBackground(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        let whitePixelsCount = 0;
        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];
          const alpha = data[i + 3];
          // Check if the pixel is close to white
          if (red > 230 && green > 230 && blue > 230 && alpha > 200) {
            whitePixelsCount++;
          }
        }
        const totalPixels = imageData.width * imageData.height;
        // Determine the ratio of white pixels
        const whiteRatio = whitePixelsCount / totalPixels;
        // If more than 80% of the image is white, consider it to have a white background
        if (whiteRatio > 0.8) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.src = event.target.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}
