import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { serverLink } from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import axios from "axios";
import { toast } from 'react-toastify';
import banner from "../../images/login_pics-min.png";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

const ChangeProgramme = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [IsLoading, setIsLoading] = useState(false);
    const [courseOptions, setCourseOptions] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [formData, setFormData] = useState({
        CourseID: "",
        CourseID2: "",
        AppID: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const getData = async () => {
        await axios.get(`${serverLink}foundation/login/get-course-list`).then((res) => {
            let rows = [];
            if (res.data.length > 0) {
                res.data.map((row) => {
                    rows.push({
                        value: row.EntryID, label: row.CourseName
                    })
                })
                setCourseList(res.data)
                setCourseOptions(rows)
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.CourseID.toString().trim() === "") {
            toast.error("Please select course");
            return false;
        }
        if (formData.AppID.toString().trim() === "") {
            toast.error("Please enter Application ID");
            return false;
        }


        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}foundation/login/change/programme`, formData)
            .then(async (result) => {
                const response = result.data.message;
                if (response === 'success') {
                    toast.success("Chnage of programme successfully initiated");
                    navigate("/");
                    setFormData({
                        ...formData,
                        CourseID: "",
                        CourseID2: "",
                        AppID: ""
                    })
                    setIsLoading(false)
                } else if (response === 'not found') {
                    toast.error("Student not found or you have already apply for change of course!");
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            }).catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })
    }

    const handleCourseChange = (e) => {
        setFormData({
            ...formData,
            CourseID: e.value,
            CourseID2: e
        })
    }

    return (
        <>
            <section className="teacher__area mb-5">
                <div className="container">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-6 hide-mobile-view" style={{ background: `url(${banner})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '700px', display: 'none' }}></div>
                            <div className="col-md-6" style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
                                <div className=" row d-flex justify-content-center align-items-center h-100">
                                    <div className="col-md-12 mt-5">
                                        <div className=" mb-2">
                                            <form className=" bg-white p-3">
                                                <div className="text-left mb-30">
                                                    <div className="section-title">
                                                        <h1 className="center-text" style={{ textAlign: 'center' }}>Apply change of programme</h1>
                                                        <b className="text-center" style={{ textAlign: 'center' }}>Note: You can only apply once.</b>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-5">
                                                    {/* <label htmlFor="CourseID" className="col-md-12 col-lg-12 col-form-label">Course Name</label> */}
                                                    <Select
                                                        id="CourseID"
                                                        className="form-select w-100"
                                                        value={formData.CourseID2}
                                                        onChange={handleCourseChange}
                                                        options={courseOptions}
                                                        placeholder="Select Course"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control mt-4" id="AppID" name="AppID" value={formData.AppID} onChange={handleChange} placeholder="Application ID Numbers only (2454)"/><span
                                                    className="focus-border"></span>
                                                </div>
                                                <button type="button" onClick={handleSubmit} className="btn btn-success mt-3 w-100">SUBMIT
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default ChangeProgramme;