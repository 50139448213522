import { useState } from "react";
import "./admission-letter.css";
import { formatDateAndTime, serverLink, toTitleCase } from "../url";
import BgImage from "../../images/logo_three.png";
import VCSign from "../../images/rislansign.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import TermsAndConditions from "./terms-and-conditions";

const AdmissionLetter = (props) => {
  const navigate = useNavigate();
  const ApplicationID = Number(window.location.pathname.match(/(\d+)$/)[0]);
  const componentRef = useRef();
  const [showAdmissionLetter, setShowAdmissionLetter] = useState(false);
  const [showUndertaking, setshowUndertaking] = useState(false);
  const componentRef_2 = useRef();
  const [data, setData] = useState([]);
  const hasPrintingAdmissionLoaded =
    JSON.parse(localStorage.getItem("hasPrintingAdmissionLoaded")) ?? 0;
  const hasPrintingUndertakenLoaded =
    JSON.parse(localStorage.getItem("hasPrintingUndertakenLoaded")) ?? 0;

  const [formData, setFormData] = useState({
    EntryID: 1,
    FirstName: "",
    MiddleName: "",
    Surname: "",
    EmailAddress: "",
    PhoneNumber: "",
    Organisation: "",
    HighestQualification: "",
    AreaOfTraining: "",
    EmploymentStatus: "",
    CourseID: "",
    CourseName: "",
    JobLevel: "",
    Cohort: "",
    CohortCode: "",
    CohortName: "",
    Status: "",
    DepartmentID: "",
    DurationType: "",
    ModeOfTeaching: "",
    Price: 0,
    ApplicationID: "",
    Passport: "",
    StudentID: "",
  });

  if (ApplicationID === "") {
    navigate("/");
  }

  const getData = async () => {
    await axios
      .get(`${serverLink}foundation/admission-letter/${ApplicationID}`)
      .then((res) => {
        if (res.data.length > 0) {
          setFormData({
            ...formData,
            EntryID: res.data[0]?.EntryID,
            FirstName: res.data[0]?.FirstName,
            MiddleName: res.data[0]?.MiddleName,
            Surname: res.data[0]?.Surname,
            EmailAddress: res.data[0]?.EmailAddress,
            PhoneNumber: res.data[0]?.PhoneNumber,
            Organisation: res.data[0]?.Organisation,
            HighestQualification: res.data[0]?.HighestQualification,
            AreaOfTraining: res.data[0]?.AreaOfTraining,
            EmploymentStatus: res.data[0]?.EmploymentStatus,
            CourseID: res.data[0]?.CourseID,
            CourseName: res.data[0]?.CourseName,
            Cohort: res.data[0]?.Cohort,
            CohortCode: res.data[0].CohortCode,
            StartDate: res.data[0].StartDate,
            Duration: res.data[0].Duration,
            Status: res.data[0]?.Status,
            ApplicationID: res.data[0]?.ApplicationID,
            Passport: res.data[0]?.Passport,
            StudentID: res.data[0]?.StudentID,
          });
          setData(res.data);
        } else {
          window.location.href = "/";
        }
      })
      .catch((e) => {
        console.log("network error");
      });
  };

  useEffect(() => {
    getData().then((r) => {});
  }, [ApplicationID, hasPrintingAdmissionLoaded, hasPrintingUndertakenLoaded]);

  const printAdmission = () => {
    if (hasPrintingAdmissionLoaded === 0) {
      localStorage.setItem("hasPrintingAdmissionLoaded", JSON.stringify(1));
      window.location.reload();
    } else if (hasPrintingAdmissionLoaded === 1) {
      localStorage.setItem("hasPrintingAdmissionLoaded", JSON.stringify(2));
      window.location.reload();
    } else if (hasPrintingAdmissionLoaded === 2) {
      setShowAdmissionLetter(true);
      setTimeout(() => {
        handlePrint();
        setShowAdmissionLetter(false);
      }, 300);
      localStorage.setItem("hasPrintingAdmissionLoaded", JSON.stringify(0));
    }
  };

  const printUndertaking = () => {
    if (hasPrintingUndertakenLoaded === 0) {
      localStorage.setItem("hasPrintingUndertakenLoaded", JSON.stringify(1));
      window.location.reload();
    } else if (hasPrintingUndertakenLoaded === 1) {
      localStorage.setItem("hasPrintingUndertakenLoaded", JSON.stringify(2));
      window.location.reload();
    } else if (hasPrintingUndertakenLoaded === 2) {
      setshowUndertaking(true);
      setTimeout(() => {
        handleUndertakingPrint();
        setshowUndertaking(false);
      }, 300);
      localStorage.setItem("hasPrintingUndertakenLoaded", JSON.stringify(0));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleUndertakingPrint = useReactToPrint({
    content: () => componentRef_2.current,
  });

  return (
    <div>
      <div
        className="pcoded-inner-content"
        style={{ fontFamily: "monospace", fontSize: "15px" }}
      >
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="row justify-content-md-center"
                    style={{ textAlign: "center" }}
                  >
                    <div className="col-md-10 mt-20">
                      {/*<h1 className="wow fadeInUp" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>Congratulations</h1>*/}
                      <span
                        className="wow fadeInUp"
                        data-wow-delay="0.2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                          fontSize: "20px",
                        }}
                      >
                        {/*<h6>Download your admission letter</h6>*/}
                      </span>
                    </div>
                    <div className="col-md-12">
                      <div className="hero-image">
                        <img className="img-fluid" src="" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="card m-5 p-5">
                    <div className="card-block p-10 ">
                      <div>
                        <blockquote className="blockquote">
                          <h4
                            style={{ fontSize: "15px" }}
                            className="sub-title"
                          >
                            Registration Guidelines
                          </h4>
                          <p style={{ fontSize: "15px" }}>
                            Follow these steps to register:
                          </p>
                        </blockquote>
                      </div>

                      <hr />
                      <div className="row" style={{ textAlign: "left" }}>
                        <div className="col-md-6">
                          <div className="col-sm-12 col-xs-12">
                            <ol>
                              <li>
                                <a
                                  href={`https://ideas.bazeuniversity.edu.ng/student/passport/${ApplicationID}`}
                                >
                                  Upload passport photograph
                                </a>
                              </li>
                              <li>
                                {" "}
                                Download Admission Letter and other supporting
                                documents
                              </li>
                            </ol>
                          </div>
                          <small>
                            Note: You must click three times to verify your
                            identity. After the third click, the file will
                            download.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="content d-flex flex-column flex-column-fluid"
                  id="kt_content"
                >
                  <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div
                      id="kt_content_container"
                      className="container-fluid"
                      style={{ maxWidth: "95rem" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card p-3  mt-2 mb-3">
                            <div className="card-block">
                              <h5>Admission Letter</h5>
                              {data.length > 0 && formData.Passport !== null ? (
                                <button
                                  className="btn btn-md btn-success w-100"
                                  onClick={printAdmission}
                                >
                                  Download admission letter
                                </button>
                              ) : (
                                <a
                                  href={`https://ideas.bazeuniversity.edu.ng/student/passport/${ApplicationID}`}
                                >
                                  Upload passport photograph
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card p-3  mt-2 mb-3">
                            <div className="card-block">
                              <h5>Terms and Conditions</h5>
                              {data.length > 0 && formData.Passport !== null ? (
                                <button
                                  className="btn btn-md btn-success w-100"
                                  onClick={printUndertaking}
                                >
                                  Download terms and condition{" "}
                                </button>
                              ) : (
                                <a
                                  href={`https://ideas.bazeuniversity.edu.ng/student/passport/${ApplicationID}`}
                                >
                                  Upload passport photograph
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="card p-3 mt-2 mb-3">*/}
                        {/*        <div className="card-block">*/}
                        {/*            <h5>Payment/Enrolment</h5>*/}
                        {/*            <button className="btn btn-md btn-secondary w-100" onClick={paymentInitialize} >Click here to make payment</button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {showAdmissionLetter === true && (
                  <LetterTemplate componentRef={componentRef} data={data} />
                )}
                {showUndertaking && (
                  <TermsAndConditions
                    componentRef={componentRef_2}
                    data={data}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LetterTemplate = (props) => {
  return (
    <div>
      {props.data.length > 0 && (
        <div
          style={{
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="bg body"
            style={{
              paddingBottom: "5px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
            ref={props.componentRef}
          >
            <div className="table-wrapper">
              <div>
                <br />
                <p className="p-text">
                  <strong>Application ID:</strong> APP/
                  {props.data[0]?.CohortCode}/{props.data[0]?.ApplicationID}
                  <b style={{ float: "right" }}>
                    {formatDateAndTime(props.data[0]?.InsertedDate, "date")}
                  </b>
                  <br />
                  <strong>Applicant Name:</strong>{" "}
                  {toTitleCase(props.data[0]?.FirstName) +
                    " " +
                    toTitleCase(props.data[0]?.MiddleName) +
                    " " +
                    toTitleCase(props.data[0]?.Surname)}
                  <br />
                  {/*{props.data[0]?.Organisation}*/}
                  {/*<br />*/}
                </p>

                <p>Dear {toTitleCase(props.data[0]?.FirstName?.trim())},</p>
              </div>
              <br />
              <div className="title"> OFFER OF PROVISIONAL ADMISSION </div>
              <div style={{ marginTop: "-5px" }}>
                <div>
                  We are pleased to inform you that you have been offered
                  provisional admission in to the Innovation Development and
                  Effectiveness in the Acquisition of Skills (IDEAS) program to
                  undergo <strong>{props.data[0]?.CourseName}, </strong> set to
                  commence on the{" "}
                  <strong>
                    {formatDateAndTime(props.data[0]?.StartDate, "date")}.
                  </strong>{" "}
                  subject to your completion of the following requirements:
                </div>
                <div>
                  <ol>
                    <li>Upload of your passport photograph </li>
                    <li>Completion of the Digital Literacy Course.</li>
                  </ol>
                </div>
                <div>
                  Kindly note that your enrollment to the IDEAS program will be
                  finalised upon completing the requirements above. Failure to
                  meet these conditions may result in the withdrawal of this
                  offer of admission.
                </div>
                <br />
                <div>
                  Once all conditions have been met and verified, you will be
                  enrolled for <strong>{props.data[0]?.CourseName}</strong>. You
                  will also receive further instructions regarding enrollment,
                  orientation, and other relevant details for the commencement
                  of the IDEAS program. Should you have any questions or require
                  further assistance, please do not hesitate to contact us at
                  ideas@bazeuniversity.edu.ng or +234 (0) 704 317 3822.
                </div>
                <br />
                <div>
                  Lastly, as we congratulate you on your acceptance to the IDEAS
                  program, we hope that you will be inspired to make a
                  meaningful impact with all you will learn during this program.
                  We look forward to welcoming you to our esteemed program.
                </div>
                <br />
                <div>Warm Regards,</div>
                <img src={VCSign} width="180px" alt="vc sign" />
                <br />
                <strong>Dr. Rislan A. Kanya </strong>
                <strong>
                  <txt style={{ fontSize: "9px" }}>
                    MBCS, CITP, MCPN, MNCS, SMIEEE
                  </txt>
                </strong>
                <br />
                Project Leader/Deputy Vice-Chancellor IT, Research and
                Innovation
              </div>
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdmissionLetter;
