// UploadInternship.js
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../url";
import styles from "./upload.module.css";

export default function UploadInternship() {
  const [letter, setLetter] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const confirmSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("email", email);
    if (letter) formData.append("file", letter);

    try {
      const response = await fetch(`${serverLink}generate/acceptanceletter`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.message || "File uploaded successfully!");

        setTimeout(() => {
          setIsModalOpen(false);
          navigate("/generate-internship");
        }, 1000);
      } else if (response.status === 400) {
        const errorData = await response.json();
        toast.error(errorData.message || "Cannot change accepted internship");
        setIsModalOpen(false);
      } else if (response.status === 404) {
        toast.error(
          "Email not found. Redirecting to the form for your internship location..."
        );
        setIsModalOpen(false);
        window.location.href =
          "https://docs.google.com/forms/d/e/1FAIpQLSehatLE8AYnarhpX48f-Q-jOcKgAlDCEd3UFWsGi1kiTm6brw/viewform";
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "An unexpected error occurred.");
        setIsModalOpen(false);
      }
    } catch (error) {
      toast.error("A network error occurred. Please try again later.");
      setIsModalOpen(false);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <img className={styles.img} src="/Rectangle.png" alt="Background" />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.form}>
          <h2 className={styles.heading}>
            Fill in the form to submit your acceptance letter
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className={styles.input}
              required
            />
            <input
              type="file"
              onChange={(e) => setLetter(e.target.files[0])}
              className={styles.input}
              required
              accept="application/pdf, image/*"
            />
            <button className={styles.button} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3 className={styles.modalTitle}>Warning</h3>
            <p className={styles.modalDescription}>
              Please note Acceptance letter can only be submitted once. Are you
              sure you want to proceed?
            </p>
            <div className={styles.modalButtons}>
              <button
                className={`${styles.modalButton} ${styles.cancelButton}`}
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
              <button
                className={`${styles.modalButton} ${styles.confirmButton}`}
                onClick={confirmSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
