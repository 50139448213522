import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import InternShipForm from "./Internship";

// Replace with your actual server link

const styles = {
  container: {
    display: "flex",
    minHeight: "100vh",
  },
  leftSection: {
    flex: 1,
    width: "50%",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "white",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function CreateInternship() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <img style={styles.img} src="/Rectangle.png" alt="Background" />
      </div>
      <div style={styles.rightSection}>
        <InternShipForm />
      </div>
    </div>
  );
}
