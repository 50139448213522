import 'swiper/swiper.min.css';
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {setHomePageData} from "../../../action/actions";
import slider1 from "../../../images/slider/3/slider-1.jpg";
import slider2 from "../../../images/slider/3/slider-2.jpg";
import foundation_banner from "../../../images/slider/Foundation_New.png";

import ReactOwlCarousel from "react-owl-carousel";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Parallax, Pagination, Navigation } from "swiper";
import {isMobile} from 'react-device-detect';
import SwiperCore, { Autoplay } from 'swiper';
import {serverLink} from "../../url";
import {Link} from "react-router-dom";


const Slider = (props) => {
    SwiperCore.use([Autoplay]);
    const [sliders, setSliders] = useState(props.homePageData.slider ? props.homePageData.slider : null);

    return (
        <>
        <section className="slider__area">
            <div className="slider__active swiper-container">
                <div  className="swiper-wrapper" style={{height: isMobile ? "500px" : "775px"}}>
                    <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }} pagination={{
                        clickable: true,
                    }} navigation={true} modules={[Autoplay, Pagination, Navigation]} className="mySwiper">


                            {sliders.map((item, index) => {
                                return(
                                    <SwiperSlide>
                                    <div className="slider__item slider__height slider__height-3 d-flex align-items-center z-index-1">
                                        <div className="slider__bg slider__overlay slider__overlay-3 include-bg">
                                            <img
                                                src={`${serverLink}public/uploads/ideas_project/slider/${item.Image}`}
                                                alt="Slider1"
                                                className="move-img"
                                            />
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 col-sm-10">
                                                    <div className="slider__content-3 p-relative z-index-1">
                                                        {
                                                            index === 0 ?
                                                                <span data-animation="fadeInUp" data-delay=".3s" style={{fontSize: '12px'}}>
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                                     xmlns="../../../../www.w3.org/2000/svg.html">
                                                <path
                                                    d="M8.745 0.4425C9.435 -0.1475 10.565 -0.1475 11.265 0.4425L12.845 1.8025C13.145 2.0625 13.705 2.2725 14.105 2.2725H15.805C16.865 2.2725 17.735 3.1425 17.735 4.2025V5.9025C17.735 6.2925 17.945 6.8625 18.205 7.1625L19.565 8.7425C20.155 9.4325 20.155 10.5625 19.565 11.2625L18.205 12.8425C17.945 13.1425 17.735 13.7025 17.735 14.1025V15.8025C17.735 16.8625 16.865 17.7325 15.805 17.7325H14.105C13.715 17.7325 13.145 17.9425 12.845 18.2025L11.265 19.5625C10.575 20.1525 9.445 20.1525 8.745 19.5625L7.165 18.2025C6.865 17.9425 6.305 17.7325 5.905 17.7325H4.175C3.115 17.7325 2.245 16.8625 2.245 15.8025V14.0925C2.245 13.7025 2.035 13.1425 1.785 12.8425L0.435 11.2525C-0.145 10.5625 -0.145 9.4425 0.435 8.7525L1.785 7.1625C2.035 6.8625 2.245 6.3025 2.245 5.9125V4.1925C2.245 3.1325 3.115 2.2625 4.175 2.2625H5.905C6.295 2.2625 6.865 2.0525 7.165 1.7925L8.745 0.4425Z"
                                                    fill="#FF8D00"/>
                                                <path d="M6.375 9.99251L8.785 12.4125L13.615 7.57251" stroke="white"
                                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                                   Get enrolled for free!

                                             </span> : <></>
                                                        }

                                                        <h2 className="slider__title-3" data-animation="fadeInUp"
                                                            data-delay=".6s">{item.StartText}<br /> {item.EndText}</h2>

                                                        <div className="slider__search mb-20" data-animation="fadeInUp"
                                                             data-delay=".9s">

                                                        </div>
                                                        <div className="slider__list" data-animation="fadeInUp" data-delay="1.2s">
                                                            <ul>
                                                                <li>
                                                                    {/*<i className="fa-solid fa-check"></i>*/}
                                                                    {item.CallToAction}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <br />
                                                        <Link type="submit" className="tp-btn-5 tp-btn-13" to='/our-programs'>Explore Programmes</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </SwiperSlide>
                                );
                            })}

                    </Swiper>
                        {/*<SwiperSlide>*/}
                        {/*    <div className="slider__item slider__height slider__height-3 d-flex align-items-center z-index-1" >*/}
                        {/*        <div className="slider__bg slider__overlay slider__overlay-3 include-bg">*/}
                        {/*                        <img*/}
                        {/*                            src={slider1}*/}
                        {/*                            alt="Slider1"*/}
                        {/*                        />*/}
                        {/*                    </div>*/}


                        {/*                    <div className="container">*/}
                        {/*                        <div className="row">*/}
                        {/*                            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 col-sm-10">*/}
                        {/*                                <div className="slider__content-3 p-relative z-index-1">*/}
                        {/*                 <span data-animation="fadeInUp" data-delay=".3s">*/}
                        {/*                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none"*/}
                        {/*                         xmlns="../../../../www.w3.org/2000/svg.html">*/}
                        {/*                    <path*/}
                        {/*                        d="M8.745 0.4425C9.435 -0.1475 10.565 -0.1475 11.265 0.4425L12.845 1.8025C13.145 2.0625 13.705 2.2725 14.105 2.2725H15.805C16.865 2.2725 17.735 3.1425 17.735 4.2025V5.9025C17.735 6.2925 17.945 6.8625 18.205 7.1625L19.565 8.7425C20.155 9.4325 20.155 10.5625 19.565 11.2625L18.205 12.8425C17.945 13.1425 17.735 13.7025 17.735 14.1025V15.8025C17.735 16.8625 16.865 17.7325 15.805 17.7325H14.105C13.715 17.7325 13.145 17.9425 12.845 18.2025L11.265 19.5625C10.575 20.1525 9.445 20.1525 8.745 19.5625L7.165 18.2025C6.865 17.9425 6.305 17.7325 5.905 17.7325H4.175C3.115 17.7325 2.245 16.8625 2.245 15.8025V14.0925C2.245 13.7025 2.035 13.1425 1.785 12.8425L0.435 11.2525C-0.145 10.5625 -0.145 9.4425 0.435 8.7525L1.785 7.1625C2.035 6.8625 2.245 6.3025 2.245 5.9125V4.1925C2.245 3.1325 3.115 2.2625 4.175 2.2625H5.905C6.295 2.2625 6.865 2.0525 7.165 1.7925L8.745 0.4425Z"*/}
                        {/*                        fill="#FF8D00"/>*/}
                        {/*                    <path d="M6.375 9.99251L8.785 12.4125L13.615 7.57251" stroke="white"*/}
                        {/*                          strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                        {/*                    </svg>*/}
                        {/*                    GET 30% off on fast enroll</span>*/}
                        {/*                                    <h2 className="slider__title-3" data-animation="fadeInUp"*/}
                        {/*                                        data-delay=".6s">Launch Your <br /> Own online learning Platform</h2>*/}

                        {/*                                    <div className="slider__search mb-20" data-animation="fadeInUp"*/}
                        {/*                                         data-delay=".9s">*/}
                        {/*                                        <form action="#">*/}
                        {/*                                            <div className="slider__search-input p-relative">*/}
                        {/*                                                <input type="text" placeholder="Course title here..." />*/}
                        {/*                                                <button type="submit">Search</button>*/}
                        {/*                                                <div className="slider__search-input-icon">*/}
                        {/*                                                    <svg width="18" height="18" viewBox="0 0 18 18"*/}
                        {/*                                                         fill="none"*/}
                        {/*                                                         xmlns="../../../../www.w3.org/2000/svg.html">*/}
                        {/*                                                        <path*/}
                        {/*                                                            d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"*/}
                        {/*                                                            stroke="#828282" strokeWidth="1.5"*/}
                        {/*                                                            strokeLinecap="round" strokeLinejoin="round"/>*/}
                        {/*                                                        <path d="M16.5 16.5L15 15" stroke="#828282"*/}
                        {/*                                                              strokeWidth="1.5" strokeLinecap="round"*/}
                        {/*                                                              strokeLinejoin="round"/>*/}
                        {/*                                                    </svg>*/}
                        {/*                                                </div>*/}
                        {/*                                            </div>*/}
                        {/*                                        </form>*/}
                        {/*                                    </div>*/}
                        {/*                                    <div className="slider__list" data-animation="fadeInUp" data-delay="1.2s">*/}
                        {/*                                        <ul>*/}
                        {/*                                            <li><i className="fa-solid fa-check"></i> Access more then 120k*/}
                        {/*                                                online courses.*/}
                        {/*                                            </li>*/}
                        {/*                                            <li><i className="fa-solid fa-check"></i> Skilled and experienced*/}
                        {/*                                                coaches.*/}
                        {/*                                            </li>*/}
                        {/*                                        </ul>*/}
                        {/*                                    </div>*/}
                        {/*                                </div>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*</SwiperSlide>*/}

                </div>
            </div>
        </section>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
