import {setTimestamp} from "../action/actions";

export const HomePageDataReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_HOMEPAGE_DATA":
            return action.payload;
        default:
            return state;
    }
}

export const HomeReportReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_HOME_REPORT_DATA":
            return action.payload;
        default:
            return state;
    }
}

export const StudentDataReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_STUDENT_DATA":
            return action.payload;
        default:
            return state;
    }
}

export const AttendanceID = (state= 0, action)=>{
    switch(action.type){
        case "SET_ATTENDANCE_ID":
            return action.payload;
        default:
            return state;
    }
}

export const Timestamp = (state= '0', action)=>{
    switch(action.type){
        case "SET_TIMESTAMP":
            return action.payload;
        default:
            return state;
    }
}
