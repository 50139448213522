import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {currencyConverter, formatDateAndTime} from "../url";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {serverLink} from "../url";
import "../home/style.css";

const News = (props) => {
    const [newsList, setNewsList] = useState(typeof props.HomeReport.news !== 'undefined' ? props.HomeReport.news : []);
    const [isLoading, setIsLoading] = useState(typeof props.HomeReport.news !== 'undefined' ? props.HomeReport.news.length <= 0 : true);

    const getData = async () => {
        await axios.get(`${serverLink}foundation/news/list`)
            .then((res) => {
                if (res.data.news.length > 0){
                    const record = res.data.news;
                    setNewsList(record)
                }else{
                   setNewsList([])
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };


    useEffect(() => {
        getData()
    }, [window.location.href]);

    return <main>
        <Breadcrumb page_name={"News"}/>
        <section className="certificate__area pb-120 grey-bg-4 pt-120">
            <div className="container">
                <div className="">
                    <div className="col-xxl-12 col-xl-12 col-lg-12">

                        <div className="row">
                            <div
                                className="section__title-wrapper mb-30 d-sm-flex align-items-center ">
                                <h2 className="section__title-4 recent-news">Recent News </h2>
                            </div>
                            <div className="col-xxl-12">
                                <div className="course__tab-conent">
                                    <div className="tab-content" id="courseTabContent">

                                        <div className="tab-pane fade show active" id="grid" role="tabpanel"
                                             aria-labelledby="grid-tab">
                                            <div className="row">
                                                {
                                                    newsList.length > 0 ?
                                                        newsList.slice(0, 3).map(news => {
                                                            return <div key={news.EntryID} className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                                                <Link to={`/news/details/${news.Slug}`}>
                                                                    <div className="course__item white-bg transition-3 mb-30 shadow">
                                                                        <div className=" p-relative">
                                                                            <div className="course__price-2 mb-2">
                                                                                <img className="img-thumbnail w-100 mb-3"
                                                                                     src={`${serverLink}public/uploads/ideas_project/news/${news.ImagePath}`}
                                                                                     alt="news image"
                                                                                     style={{height: '170px'}}
                                                                                />
                                                                            </div>
                                                                            <div style={{height: '40px', textAlign: 'justify'}}>
                                                                                <h5 className="news_title">
                                                                                    <Link
                                                                                        to={`/news/details/${news.Slug}`}>
                                                                                        {news.Title}
                                                                                    </Link>
                                                                                </h5>
                                                                            </div>
                                                                            <div className="course__bottom d-sm-flex align-items-center justify-content-between mt-3">
                                                                                <div>
                                                                                    <i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(news.InsertedDate, 'date')}
                                                                                </div>
                                                                                <div>
                                                                                    <i className="fas fa-eye pr-1"></i> {news.ViewCount}
                                                                                </div>
                                                                            </div>
                                                                            <hr style={{marginTop: '8px', marginBottom: '8px', }}/>

                                                                            <div className="course__bottom d-sm-flex align-items-center justify-content-between">
                                                                                <div className="">
                                                                                    <Link to={`/news/details/${news.Slug}`}>Read More</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }) : <div className="alert alert-info text-center"><b>NO RECORD
                                                            FOUND!</b></div>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </main>
}
const mapStateToProps = (state) => {
    return {
        HomeReport: state.HomeReport
    };
};

export default connect(mapStateToProps, null)(News);
